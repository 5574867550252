import React, { useEffect, useState } from "react";
import "../css/wb_delivery_report.css";
import { apiCall } from "../services/authServieces";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from '../Assets/images/delete.png';


const EditGroupNumbers = () => {

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mobilesrch, setmobilesrch] = useState("");
  const [editedValues, setEditedValues] = useState({});
  const [deleteModalId, setDeleteModalId] = useState(null);

  const [showpagi, setshowpagi] = useState(false);

  const itemsPerPage = 10; // page limit

  const fetchData = async (page) => {
    try {


        let groupid=localStorage.getItem('groupid')
        
      const res = await apiCall({
        endpoint: `groups/get_groupdata?page=${page}&limit=${itemsPerPage}&groupid=${groupid}`, // Use itemsPerPage
        method: "get"
      });
 

      const result = res; // Use res instead of response
      setData(result.items);
      setTotalPages(Math.ceil(result.total / itemsPerPage));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Create pagination array
  const createPagination = () => {
    const pagesToShow = 1; // Number of pages to show around the current page
    let pages = [];

    // Add the first page
    pages.push(1);

    // Add ellipsis if the current page is far from the first page
    if (currentPage > pagesToShow + 2) {
      pages.push(null); // Ellipsis
    }

    // Add pages around the current page
    for (
      let i = Math.max(2, currentPage - pagesToShow);
      i <= Math.min(totalPages - 1, currentPage + pagesToShow);
      i++
    ) {
      pages.push(i);
    }

    // Add ellipsis if the current page is far from the last page
    if (currentPage < totalPages - pagesToShow - 1) {
      pages.push(null); // Ellipsis
    }

    // Add the last page if it's greater than 1
    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages;
  };

  const handleEdit = (id, value) => {
    // Check if the value is a number and has a maximum length of 10
    if (/^\d*$/.test(value) && value.length <= 10) {
        // Only update the state if the value has changed
        if (editedValues[id] !== value) {
            setEditedValues(prev => ({
                ...prev,
                [id]: value
            }));
        }
    }
};


// The rest of your component remains unchanged

  
  const handleSave = async (id) => {
    const updatedMobile = editedValues[id]; // Get the edited value
  
    if(typeof updatedMobile==='undefined'){
        return
    }


    if(updatedMobile.length<10){
        return alert('Please Enter Valid Number')
    }

    try {
    const respon= await apiCall({
        endpoint: `groups/update_groupnumbers`, // Adjust your endpoint accordingly
        method: "post", // Use the appropriate method (PUT or POST)
        payload: { mobile: updatedMobile,id:id }
      });

   if(respon.success===true){

    alert('Mobile Number Update Successfully')

         // Optionally, reset editedValues if the save is successful
         setEditedValues(prev => ({ ...prev, [id]: undefined }));
         // Fetch updated data if needed
         fetchData(currentPage);


   }else{

    alert('Please Try Again')

    fetchData(currentPage);

   }
 
    } catch (error) {
      console.error("Error saving mobile:", error);
    }
  };
  
  const handleDelete = async (id) => {
    // Make your API call to delete the entry
    try {
     let respon= await apiCall({
        endpoint: `groups/delete_groupnumbers`, // Adjust your endpoint accordingly
        method: "post",
        payload:{id:id}
      });

      if(respon.success===true){

        alert('Mobile Number Delete Successfully');

           // Fetch updated data after deletion
        fetchData(currentPage);
        setDeleteModalId(null);

      }else{

        
    alert('Please Try Again')

    fetchData(currentPage);


      }
   
    } catch (error) {
      console.error("Error deleting entry:", error);
    }
  };
  

  return (
    <>
      <div className="Delivery_report_contian">
        <h4 className="Head_title">
         Group Numbers
          {/* <div className="SearchContain">
            <input
              type="text"
              placeholder="Search"
              value={mobilesrch}
              onChange={(e) => setmobilesrch(e.target.value)}
            />
            <button  type="button"  onClick={Handlesrchno}>
              <SearchIcon />
            </button>
          </div>{" "} */}
        </h4>

        <div className="Delivery_report_Card">
          <div className="table_contain_deliveryReport">
            <table className="Table" id="Table">
              <thead>
                <tr>
                  <th>S_No.</th>
                  <th>Add Date & Time</th>
                  <th>Mobile</th>
                  <th>Action</th>
        
                </tr>
              </thead>
              <tbody>
              {data.map((item, index) => (
    <tr key={index}>
    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
    <td>
      {item.cdate} <br /> {item.ctime}
    </td>
    <td>
    <input
    type="text"
    value={editedValues[item.id] !== undefined ? editedValues[item.id] : item.mobile} // Default to item.mobile unless editedValues[item.id] is defined
    onChange={e => handleEdit(item.id, e.target.value)} // Call a function to handle changes
/>

    </td>
    <td>
      <button onClick={() => handleSave(item.id)} className="download_icon me-2">Save</button>
      <button  onClick={() => setDeleteModalId(item.id)}><img src={DeleteIcon} alt="icon" /></button>
      {deleteModalId === item.id && (
                        <div className="DeleteConfirmModalContain">
                          <div className="DeleteConfirmModal">
                            <p>Are you sure you want to delete?</p>
                            <div className="modal-buttons">
                              <button type='button' className='Modal_save_btn download_icon' onClick={() => handleDelete(item.id)}>Yes</button>
                              <button type="button" className="Modal_cancel_btn" onClick={() => setDeleteModalId(null)}>No</button>
                            </div>
                          </div>
                        </div>
                      )}
    </td>
  </tr>
))}

              </tbody>
            </table>
          </div>
        </div>
        <div className={`pagination  ${showpagi ? "d-none" : ""}`}>
          {createPagination().map((page, index) =>
            page === null ? (
              <span key={index} className="ellipsis">
                ...
              </span>
            ) : (
              <button
              key={index}
              className={`page-button ${currentPage === page ? 'active' : ''}`}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default EditGroupNumbers;
