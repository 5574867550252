import React, { useEffect, useState } from "react";
import "./../../css/oemDashboard.css";
import TodayCard from "../../components/TodayCard";
import Total from "../../Assets/images/total.png";
import Delivered from "../../Assets/images/delivered.png";
import Failed from "../../Assets/images/failed.png";
import Read from "../../Assets/images/read.png";
import Send from "../../Assets/images/send.png";
import Receive from "../../Assets/images/recieve.png";
import ChartComponent from "../../components/ChartComponent";
import BarData from "../../components/sidebar/BarData";
import { apiCall } from "../../services/authServieces";
import { ClipLoader } from "react-spinners";

const OemAdminDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [total_hsm, setDashboardData] = useState(0);
  const [delivered_hsm, setDeliveredHsm] = useState(0);
  const [failed_hsm, setFailedHsm] = useState(0);
  const [seen_hsm, setSeenHsm] = useState(0);
  const [sessionData, setSessionData] = useState({
    totalSession: "",
    sendSession: "",
    receivedSession: "",
    seenSession: "",
  });

  const [chartData, setChartData] = useState({
    read: Array(10).fill(0),
    failed: Array(10).fill(0),
    delivered: Array(10).fill(0),
  });

  const [DataForBarGraph, setBarGraphData] = useState({
    sendHSM: "",
    Failed: "",
    seenData: "",
    sendHsmPercentage: "",
    FailedHsmPercentage: "",
    seenDataPercentage: "",
  });

  const getLast10DaysLabels = () => {
    let labels = [];
    let currentDate = new Date();

    for (let i = 0; i < 10; i++) {
      let date = new Date();
      date.setDate(currentDate.getDate() - i);

      let formattedDate = date.toLocaleString("default", {
        month: "short",
        day: "2-digit",
      });
      labels.unshift(formattedDate);
    }

    return labels;
  };

  const dynamicLabels = getLast10DaysLabels();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true)
      let res = await apiCall({
        endpoint: "oem/get_oem_admin_dashboard_data",
        method: "GET",
      });
      setLoading(true)
      setDashboardData(res.data.total_hsm);
      setDeliveredHsm(res.data.send_count);
      setFailedHsm(res.data.failed_count);
      setSeenHsm(res.data.seen_count);

      setSessionData({
        totalSession: res.data.session_total,
        sendSession: res.data.send_session,
        receivedSession: res.data.received_session,
        seenSession: res.data.seen_session,
      });

      //  data for 10 days bar ///

      // Process the lastTenDaysData to set the chart data
      const lastTenDaysData = res.data.lastTenDaysData;

      const readData = [];
      const failedData = [];
      const deliveredData = [];

      // Initialize data with zeros
      for (let i = 0; i < 10; i++) {
        readData.push(0);
        failedData.push(0);
        deliveredData.push(0);
      }

      // Fill data based on fetched lastTenDaysData
      lastTenDaysData.forEach((item) => {
        if (item.send_date) {
          const sendDateIndex = dynamicLabels.indexOf(
            new Date(item.send_date).toLocaleString("default", {
              month: "short",
              day: "2-digit",
            })
          );

          if (sendDateIndex >= 0) {
            // const total = item.count_delivered + item.count_failed + item.count_seen;
            const total = item.total_count;

            const deliveredcount = item.count_delivered + item.count_seen;

            // Calculate percentage only if the total is greater than 0 to avoid division by zero
            if (total > 0) {
              // console.log((item.count_seen/7)*100,'(item.count_seen / total)')
              readData[sendDateIndex] = (item.count_seen / total) * 100;
              failedData[sendDateIndex] = (item.count_failed / total) * 100;
              deliveredData[sendDateIndex] = (deliveredcount / total) * 100;
            }
          }
        }
      });

      // Update chart data state
      setChartData({
        read: readData,
        failed: failedData,
        delivered: deliveredData,
      });

      // chart for one day data

      // get percentage data

      let totalData = res.data.total_count;
      let SendData = res.data.send_count;
      let failedData1 = res.data.failed_count;
      let seenData = res.data.seen_count;

      let sendPercentage =
        totalData === 0 ? 0 : Math.round((SendData / totalData) * 100);
      let failedPercentage =
        totalData === 0 ? 0 : Math.round((failedData1 / totalData) * 100);
      let seenPercentage =
        totalData === 0 ? 0 : Math.round((seenData / totalData) * 100);

      setBarGraphData({
        sendHSM: res.data.send_count,
        Failed: res.data.failed_count,
        seenData: res.data.seen_count,
        sendHsmPercentage: sendPercentage,
        FailedHsmPercentage: failedPercentage,
        seenDataPercentage: seenPercentage,
      });
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err);
    }
  };

  return (
    <>
      <div className="Dashboard_contain">
        <div className="Dashboard_card">
          <h5>(OEM) HSM Dashboard</h5>

          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
              <TodayCard
                className="Today_card_img bg_submission"
                data={total_hsm}
                header="Total HSM"
                src={Total}
              />
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
              <TodayCard
                className="Today_card_img bg_delivered"
                data={delivered_hsm}
                header="Total Delivered "
                src={Delivered}
              />
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
              <TodayCard
                className="Today_card_img bg_failed"
                data={failed_hsm}
                header="Today Failed "
                src={Failed}
              />
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
              <TodayCard
                className="Today_card_img bg_pending"
                data={seen_hsm}
                header="HSM Read"
                src={Read}
              />
            </div>
          </div>
          <div className="border_top">
            <h5>Session Dashboard</h5>
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                <TodayCard
                  className="Today_card_img bg_submission"
                  data={sessionData.totalSession}
                  header="Total Session"
                  src={Total}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                <TodayCard
                  className="Today_card_img bg_delivered"
                  data={sessionData.sendSession}
                  header="Send Session"
                  src={Send}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                <TodayCard
                  className="Today_card_img bg_failed"
                  data={sessionData.receivedSession}
                  header="Total Receive Session"
                  src={Receive}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                <TodayCard
                  className="Today_card_img bg_pending"
                  data={sessionData.seenSession}
                  header="Session Read"
                  src={Read}
                />
              </div>
            </div>
          </div>

          <div className="Recharge_section">
            <div className="row gy-4">
              <div className="col-lg-5">
                <div className="Today_bar">
                  <BarData title="Today HSM Delivery" data={DataForBarGraph} />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="Today_bar">
                  <ChartComponent
                    title="HSM Report (Last 10 days)"
                    chartData={chartData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="spinnerOverlay">
          <ClipLoader loading={loading} size={50} />
        </div>
      )}
    </>
  );
};

export default OemAdminDashboard;
