import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./oemsidebar.css";
import List from "../../Assets/images/list.png";
import CloseIcon from "../../Assets/images/close_icon.png";
import Dash from "../../Assets/images/dashboard.png";
import Manage from "../../Assets/images/manage.png";
import transactionIcon from "../../Assets/images/transaction.png";
import { useUIContext } from "../../context/index";
import Logo from "../../Assets/images/logo.png";
import Report from "../../Assets/images/report.png";
import Wallet from "../../Assets/images/wallet.png"

const OemSidebar = () => {
  const location = useLocation();
  const { isSidebarCollapsed, setIsSidebarCollapsed, userType } =
    useUIContext();

  const isActivePath = (path) => location.pathname === path;

  return (
    <div className="Sidebar" id="Sidebar">
      <div className="Sidebar_head">
        {/* <NavLink to="#"><img src={SmsIcon} alt="img" /></NavLink> */}
        <NavLink to="#">
          <img src={Logo} alt="img" />
        </NavLink>
        <NavLink to="#">EASYGOWP</NavLink>
        <button
          id="Menu_close"
          onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
        >
          <img src={CloseIcon} alt="img" />
        </button>
      </div>
      <div className="Sidebar_contain">
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <NavLink to="/">
                <button
                  className={`accordion-button bg_none collapsed ${
                    isActivePath("/") ? "active" : ""
                  }`}
                  type="button"
                >
                  <img src={Dash} alt="img" /> <span>Dashboard</span>
                </button>
              </NavLink>
            </h2>
          </div>
       

          <div className="accordion-body1">
                <NavLink
                  to="/oem/delivery-report"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <img src={Report} alt="img" />
                  Live Report
                </NavLink>
                <NavLink
                  to="/oem/mis-report"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <img src={Manage} alt="img" />
                  Mis Report
                </NavLink>
{/*              
                <NavLink
                  to="/admin/wallet-alert"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <img src={transactionIcon} alt="img" />
                  Wallet Alert
                </NavLink> */}

                <NavLink
                  to="/oem/wallet-data"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <img src={Wallet} alt="img" />
                  Wallet
                </NavLink>
              </div>
             
             

          {/* Add more admin-specific sidebar items */}
        </div>
      </div>
      <div className="sidebar_bg_img"></div>
    </div>
  );
};

export default OemSidebar;
