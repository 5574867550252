import React from 'react'

import './footer.css';

const Footer = () => {
  return (
    <footer className="Footer">
        <p>Services By : Manthan IT Solutions</p>
    </footer>
  )
}

export default Footer;