import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/NotFound.css'; 
import notFoundGif from '../Assets/images/404ErrorPage.gif';

const NotFound = () => {
  const navigate = useNavigate();

  const handleHomeRedirect = () => {
    navigate('/');
  };

  return (
    <div className="not-found-container">
      <img src={notFoundGif} alt="404 Not Found" className="not-found-gif" />
      <h1 className="not-found-title">404 - Page Not Found</h1>
      <p className="not-found-message">
        Oops! The page you're looking for doesn't exist.
      </p>
      <button onClick={handleHomeRedirect} className="not-found-button">
        Return to Home Page
      </button>
    </div>
  );
};

export default NotFound;
