import React from "react";

const BarData = ({ title, data }) => {
  // const { sendHSM, Failed, seenData } = data;

  let sendHSM=data.sendHSM ?? 0;
  let Failed=data.Failed ?? 0;
  let seenData=data.seenData ?? 0;
  

  const totalData = sendHSM + Failed + seenData;
  const sendHsmPercentage = totalData === 0 ? 0 : Math.round((sendHSM / totalData) * 100);
  const failedPercentage = totalData === 0 ? 0 : Math.round((Failed / totalData) * 100);
  const seenPercentage = totalData === 0 ? 0 : Math.round((seenData / totalData) * 100);

  return (
    <>
      <h4>{title}</h4>
      <div className="Bar_contain">
        <div className="Submission_bar" style={{ width: `${seenPercentage}%`, backgroundColor: "#00acc1" }}></div>
        <div className="Failed_bar" style={{ width: `${failedPercentage}%`, backgroundColor: "#e53935" }}></div>
        <div className="Delivered_bar" style={{ width: `${sendHsmPercentage}%`, backgroundColor: "#8cc751" }}></div>
        <div className="Bar_persent">
          <p>
            Read: <span id="submission_percent">{seenData}</span>
          </p>
          <p>
            Failed: <span id="failed_percent">{Failed}</span>
          </p>
          <p>
            Delivered: <span id="delivered_percent">{sendHSM}</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default BarData;
