import React, { useEffect, useState } from "react";
import "../css/wb_session_recieved_report.css";
import Export from "../Assets/images/export.png";
import Search from "../Assets/images/search.png";
import { apiCall } from "../services/authServieces";
import SearchIcon from "@mui/icons-material/Search";
import CustomDateRangePicker from "../components/CustomDatePicker";
import excelIcon from "../Assets/images/excel.png";
import { useUIContext } from "../context";
import { formatDate } from "../utils/helper";
const WbSessionRecievedReport = () => {
  const [sessionData, getSessionData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mobilesrch, setmobilesrch] = useState('');
  const [showPagi, setshowPagi] = useState(false);
  const { dateRanges } = useUIContext();
  const itemsPerPage = 10;

  const fetchData = async (page) => {
    try {
      const dates = dateRanges?.two?.map(date => {
        const localDate = new Date(date);
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
        const day = String(localDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`; // Format as YYYY-MM-DD
      });
      const params = new URLSearchParams({
        mobileno: mobilesrch || undefined,
        fromDate: dates[0] || undefined,
        toDate: dates[1] || undefined,
        page: page,
        limit: itemsPerPage,
      });

      const res = await apiCall({
        endpoint: `report/getSessionReportData?${params.toString()}`,
        method: "GET",
      });

      const transformedData = res.data.map(item => ({
        date: `${formatDate(item.send_date)} ${item.send_time}`,
        number: item.sender_mobile,
        label: item.sender_profile_name,
        message: item.in_msg ? item.in_msg : '',
        text: item.out_msg ? item.out_msg : ''
      }));

      getSessionData(transformedData);
      setTotalPages(Math.ceil(res.total / itemsPerPage));
      setshowPagi(res.total > itemsPerPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Use useEffect only for the initial render
  useEffect(() => {
    fetchData(currentPage);
  }, []); // Empty dependency array means this will only run once when the component mounts

  const handlePageChange = (page) => {
    setCurrentPage(page); // Update current page
    fetchData(page); // Fetch data for the new page
  };

  const handleSearch = () => {
    setCurrentPage(1); // Reset to the first page on search
    fetchData(1); // Fetch data for the first page
  };

  const createPagination = () => {
    const pagesToShow = 1; // Number of pages to show around the current page
    let pages = [];

    if (totalPages > 1) {
      pages.push(1); // Always show the first page

      if (currentPage > pagesToShow + 2) {
        pages.push(null); // Add ellipsis if needed
      }

      for (
        let i = Math.max(2, currentPage - pagesToShow);
        i <= Math.min(totalPages - 1, currentPage + pagesToShow);
        i++
      ) {
        pages.push(i);
      }

      if (currentPage < totalPages - pagesToShow - 1) {
        pages.push(null); // Add ellipsis if needed
      }

      if (totalPages > 1) {
        pages.push(totalPages); // Always show the last page
      }
    }

    return pages;
  };

  const handleExport = () => {
    const headers = ['S_No.', 'Date', 'Mobile', 'Profile Name', 'In Message', 'Out Message'];
    const csvRows = [];

    // Add headers
    csvRows.push(headers.join(','));

    // Add data
    sessionData.forEach((item, index) => {
      const row = [
        (currentPage - 1) * itemsPerPage + index + 1,
        item.date,
        item.number,
        item.label,
        `"${item.message.replace(/"/g, '""').replace(/\n/g, ' ')}"`, // Replace newlines with spaces
        item.text,
      ];

      csvRows.push(row.join(','));
    });

    // Create CSV string
    const csvString = csvRows.join('\n');

    // Create a blob and download the CSV
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'session_report.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="Session_report_contian">
      <h4 className="Head_title MisReportTitle">
      <div className="DateRangeContain"><CustomDateRangePicker rangeKey="two" /><button type="button" className="SearchDateRange" onClick={handleSearch}><img src={Search} alt="Search" /></button></div>
        Session Report
        <div className="SessionExcelContain">
        <div className="Session_report_SearchContain">
        <input
          type="text"
          value={mobilesrch}
          onChange={(e) => setmobilesrch(e.target.value)}
          placeholder="Search"
        />
        <button type="button" onClick={handleSearch}>
          {/* Search */}
          <SearchIcon />
        </button>
        </div>
        
        <button type="button" className="ExcelIconContain" onClick={handleExport}>
                <img src={excelIcon} alt="excelIcon" className="ExcelIcon"/> 
              </button>
              </div>
      </h4>
      <div className="Session_report_Card12">
        {/* <div className="Date_contain gy-2">
          <div className="form_group9">
            <label htmlFor="from_date">From Date</label>
            <input
              type="date"
              id="from_date"
              className="datepicker"
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div className="form_group9">
            <label htmlFor="to_date">To Date</label>
            <input
              type="date"
              id="to_date"
              className="datepicker"
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
          <div className="form_group9">
            <button type="button" onClick={handleSearch}>
              <img src={Search} alt="img" /> Submit
            </button>
          </div>
          <div className="form_group9">
            <button type="button" className="export_btn" onClick={handleExport}>
              <img src={Export} alt="img" /> Export
            </button>
          </div>
        </div> */}
        <div className="table_contain tableSessionR">
          <table className="Table" id="Table">
            <thead>
              <tr>
                {/* <th>S_No.</th> */}
                <th>Date</th>
                <th>Mobile</th>
                <th>Profile Name</th>
                <th>In Message</th>
                <th>Out Message</th>
              </tr>
            </thead>
            <tbody>
            {sessionData.length === 0 ? (
              <tr>
                <td colSpan="6" className="tableDataCenterPosition">
                  No data available
                </td>
              </tr>
            ) : (
              sessionData.map((item, index) => (
                <tr key={index}>
                  <td className="text-nowrap">{item.date}</td>
                  <td>{item.number}</td>
                  <td>{item.label.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase())}</td>
                  <td>
                    <p className="message">
                      {item.message.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase())}
                    </p>
                  </td>
                  <td>{item.text.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase())}</td>
                </tr>
              ))
            )}

            </tbody>
          </table>
        </div>
      </div>

      {/* Merged Pagination */}
      {showPagi && (
        <div className="pagination">
          {createPagination().map((page, index) => (
            page === null ? (
              <span key={index} className="ellipsis">...</span>
            ) : (
              <button
                key={index}
                className={`page-button ${currentPage === page ? 'active' : ''}`}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            )
          ))}
        </div>
      )}
    </div>
  );
};

export default WbSessionRecievedReport;
