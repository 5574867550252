import React, { useState, useRef, useEffect } from 'react';
import "../css/datepicker.css";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useUIContext } from '../context';

const CustomDateRangePicker = ({ rangeKey, totalRanges }) => {
  const {
    dateRanges,
    setDateRanges,
  } = useUIContext();

  // Get the date range for the current key
  const dateRange = dateRanges[rangeKey];
  
  const [isOpen, setIsOpen] = useState(false);
  const calendarRef = useRef(null); // Reference for the calendar component

  const handleDateChange = (value) => {
    if (!dateRange[0]) {
      setDateRanges(prev => ({
        ...prev,
        [rangeKey]: [value, null], // Set start date
      }));
    } else if (!dateRange[1]) {
      setDateRanges(prev => ({
        ...prev,
        [rangeKey]: [dateRange[0], value], // Set end date
      }));
      setIsOpen(false); // Close the calendar after both dates are selected
    }
  };

  const toggleCalendar = () => {
    setIsOpen((prev) => !prev);
  };

  const resetDateRange = () => {
    setDateRanges(prev => ({
      ...prev,
      [rangeKey]: [null, null], // Reset date range
    }));
  };

  const formatDate = (date) => {
    return date ? date.toLocaleDateString('en-IN') : ''; // Formatting date
  };

  // Close calendar if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <button onClick={toggleCalendar} className='DateRangePikerButton'>
        {formatDate(dateRange[0]) || 'Start Date'} - {formatDate(dateRange[1]) || 'End Date'}
      </button>
      {isOpen && (
        <div className='DateRangeCalender' ref={calendarRef}>
          <Calendar
            onChange={handleDateChange}
            value={dateRange[0] || dateRange[1] ? dateRange : null}
          />
          {dateRange[0] && dateRange[1] && (
            <button onClick={resetDateRange} className='ResetButton'>
              Reset Dates
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default CustomDateRangePicker;
