import React, { useState, useEffect, useCallback } from "react";
import ShowSnackBar from "../components/snackBar";
import "../css/wb_developers_tools.css";
import UrlCard from "../components/UrlCard";
import { apiCall } from "../services/authServieces";

const WbDevelopersTools = () => {
  const [isAPIKey, setIsApiKey] = useState(false);
  const [apiKey, setApiKey] = useState({ apiKey: "", password: "" });
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const fetchData = useCallback(async () => {
    try {
      let endpoint = `api/get-api-key`;
      const response = await apiCall({ method: "GET", endpoint: endpoint });
      if (response.success) {
        if (
          response?.data?.manthan_api_key &&
          response?.data?.api_key_password
        ) {
          setIsApiKey(true);
          setApiKey({
            apiKey: response.data.manthan_api_key,
            password: response.data.api_key_password,
          });
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, []);

  const generateAPIKey = async (e) => {
    try {
      const endpoint = `api/generate-api-key`;
      const response = await apiCall({ method: "PUT", endpoint: endpoint });
      if (response.success) {
        setSnackBar({
          open: true,
          severity: true,
          message: response?.message,
        });
        await fetchData();
      }
    } catch (error) {
      setSnackBar({
        open: true,
        severity: false,
        message:
          error?.response?.data?.message ||
          "Server Error. Please try again later.",
      });
    }
  };

  const handleCloseSnackBar = (event, reason) => {
    setSnackBar((prevState) => ({ ...prevState, open: false }));
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div className="Developers_tools_contain">
        <h4 className="Head_title">Send Whatsapp HTTP API :</h4>
        <div className="Developers_tools_card">
          <p>
            API, An Abbreviation of Application Promgramme Interface, is a set
            pf routine, protocols and tools for building software applications.
            Whatsapp API allows application developer to integrate on there
            application and send the sms to all the numbers at one shot without
            login to their sms panel.
          </p>
          <div className="table_contain width222">
            {isAPIKey ? (
              <table className="Table">
                <thead>
                  <tr>
                    <th>API Key</th>
                    <th>API Password</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{apiKey.apiKey}</td>
                    <td>{apiKey.password}</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <table className="Table">
                <thead>
                  <tr>
                    <th>No API Key found!</th>
                    <th>
                      <button onClick={generateAPIKey}>Generate API Key</button>
                    </th>
                  </tr>
                </thead>
              </table>
            )}
          </div>
          <div className="url_contain">
            {/* <h4 className="cotain_title text-center">JSON API :</h4>
            <UrlCard
              header="Send Text Whatsapp API :"
              url="https://easywpbot.com/api/send_text_sms.php"
              title="Sample POST Request"
              api='"api_key":"XXXXXXX", "pass":"XXXXXXX", "muldealer_code":"XXXXXXX", "template_id":"XXXX", "mobile":"91XXXXXX", "type":"template", "sample":"abc,xyz"'
            /> */}

            {/* <UrlCard
              header="Send Document Whatsapp API :"
              url="https://easywpbot.com/api/send_doc_sms.php"
              title="Sample POST Request"
              api='"api_key":"XXXXXXX", "pass":"XXXXXXX",
                "muldealer_code":"XXXXXXX", "template_id":"XXXX",
                "mobile":"91XXXXXX", "url":"https:XXXXX", "filename":"XXXX",
                "type":"template", "sample":"abc,xyz"'
            /> */}

            {/* <UrlCard
              header="Send Document Whatsapp API :"
              url="https://easywpbot.com/api/send_doc_sms.php"
              title="Sample POST Request"
              api='"api_key":"XXXXXXX", "pass":"XXXXXXX",
                "muldealer_code":"XXXXXXX", "template_id":"XXXX",
                "mobile":"91XXXXXX", "url":"https:XXXXX", "filename":"XXXX",
                "type":"template", "sample":"abc,xyz"'
            /> */}

            {/* <UrlCard
              header="Send Image Whatsapp API :"
              url="https://easywpbot.com/api/send_img_sms.php"
              title="Sample POST Request"
              api=' "api_key":"XXXXXXX", "pass":"XXXXXXX",
                "muldealer_code":"XXXXXXX", "template_id":"XXXX",
                "mobile":"91XXXXXX", "url":"https:XXXXX", "type":"template",
                "sample":"abc,xyz"'
            /> */}

            {/* <UrlCard
              header="Send Video Whatsapp API :"
              url="https://easywpbot.com/api/send_video_sms.php"
              title="Sample POST Request"
              api='"api_key":"XXXXXXX", "pass":"XXXXXXX",
              "muldealer_code":"XXXXXXX", "template_id":"XXXX",
              "mobile":"91XXXXXX", "url":"https:XXXXX", "type":"template",
              "sample":"abc,xyz"'
            /> */}

            {/* <div className="url_card">
              <h4>Session Image Whatsapp API :</h4>
              <p>URL:https://easywpbot.com/api/session_image_sms.php</p>
              <h6>Sample POST Request</h6>
              <p>
                "api_key":"XXXXXXXX", "pass":"XXXXXX", "mobile":"91XXXXX",
                "url":"https://XXXXXX", "type":"image"
              </p>
            </div> */}

            {/* <div className="url_card">
              <h4>Session Document Whatsapp API :</h4>
              <p>URL:https://easywpbot.com/api/session_doc_sms.php</p>
              <h6>Sample POST Request</h6>
              <p>
                "api_key":"XXXXXXXX", "pass":"XXXXXX", "mobile":"91XXXXX",
                "url":"https://XXXXXX", "filename":"XXXXXX", "type":"document"
              </p>
            </div>
            <div className="url_card">
              <h4>Session Video Whatsapp API :</h4>
              <p>URL:https://easywpbot.com/api/session_video_sms.php</p>
              <h6>Sample POST Request</h6>
              <p>
                "api_key":"XXXXXXXX", "pass":"XXXXXX", "mobile":"91XXXXX",
                "url":"https://XXXXXX", "filename":"XXXXXX", "type":"video"{" "}
              </p>
            </div> */}
            {/* <div className="url_card">
              <h4>Session Audio Whatsapp API :</h4>
              <p>URL:https://easywpbot.com/api/session_audio_sms.php</p>
              <h6>Sample POST Request</h6>
              <p>
                "api_key":"XXXXXXXX", "pass":"XXXXXX", "mobile":"91XXXXX",
                "url":"https://XXXXXX", "type":"audio"{" "}
              </p>
            </div>
            <div className="url_card">
              <h4>Balance Check API :</h4>
              <p>
                https://easywpbot.com/api/balance_check.php?api_key=xxxxxxxxx&pass=xxxxxxx
              </p>
            </div> */}
            <h4 className="cotain_title text-center">URL API :</h4>
            <div className="url_card">
              <h4>Send Text Whatsapp URL API :</h4>
              <p>URL : https://apipathwp.com/api/send-text?apiKey={apiKey.apiKey}&pass={apiKey.password}&templateId=XXXX&mobile=XXXXXXXXXXX&sample=sampl1,sample2</p>
              {/* <p>
                api_key=XXXXXXX&pass=XXXXXXX&muldealer_code=XXXXXX&template_id=XXXX&mobile=91XXXXXX&type=template&sample=abc,xyz
              </p> */}
            </div>
            {/* <div className="url_card">
              <h4>Send Document Whatsapp URL API :</h4>
              <p>URL:https://easywpbot.com/api/senddoc_url_sms.php?</p>
              <p>
                api_key=XXXXXXX&pass=XXXXXXX&muldealer_code=XXXXXX&template_id=XXXX&mobile=91XXXXXX&url=https:XXXXX&filename=XXXX&type=template&sample=abc,xyz
              </p>
            </div> */}
            {/* <div className="url_card">
              <h4>Send Image Whatsapp API :</h4>
              <p>URL:https://easywpbot.com/api/send_img_url_api.php?</p>
              <p>
                api_key=XXXXXXX&pass=XXXXXXX&muldealer_code=XXXXXX&template_id=XXXX&mobile=91XXXXXX&url=https:XXXXX&type=template&sample=abc,xyz
              </p>
            </div> */}
          </div>
        </div>
      </div>
      
      <ShowSnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={snackBar.message}
        onClose={handleCloseSnackBar}
      />
    </>
  );
};

export default WbDevelopersTools;
