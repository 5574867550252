import React from 'react';
import AdminSidebar from './AdminSidebar';
import UserSidebar from './UserSidebar';
import OemSidebar from './OemSidebar';

const MainSidebar = () => {
  // Get the user data from localStorage
  const userData = JSON.parse(localStorage.getItem('user-cred'));
  const userType = userData?.user?.user_type;

  // Render the appropriate sidebar based on userType
  let sidebar = null;
  
  if (userType === 'admin' || userType === 'super_admin') {
    sidebar = <AdminSidebar />;
  } else if (userType === 'OEM') {
    sidebar = <OemSidebar />;
  } else {
    sidebar = <UserSidebar />;
  }

  return (
    <>
      {sidebar}
    </>
  );
};

export default MainSidebar;
