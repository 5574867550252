import React from 'react';
import '../css/wb_dealerwise_mis_report.css';
import Search from '../Assets/images/search.png';
import Export from '../Assets/images/export.png';
import Import from '../Assets/images/import.png';
const WbDealerwiseMisReport = () => {
  return (
    <>


    
<div className="Mis_report_contian">
    <h4 className="Head_title">Dealerwise MIS Report</h4>
    <div className="Mis_report_Card">
        <div className="Date_contain">
            <div className="form_group333">
                <label for="from_date">From Date</label>
                <input type="text" id="from_date" className="datepicker" placeholder="Enter From Date"/>
            </div>
            <div className="form_group333">
                <label for="to_date">To Date</label>
                <input type="text" id="to_date" className="datepicker" placeholder="Enter To Date"/>
            </div>
            <div className="form_group333">
                <span  className="invisible"> Date</span>
                <button type="button"><img src={Search} alt="img"/> Submit</button>
            </div>
            <div class="form_group333">
                <span  className="invisible"> table</span>
                <button type="button" className="export_btn"><img src={Export} alt="img"/> Export</button>
            </div>
        </div>
        <div className="table_contain">
            <table className="Table" id="Table">
                <thead>
                    <tr>
                        <th>Dealer Code</th>
                        <th>HSM Send</th>
                        <th>HSM Delivered</th>
                        <th>HSM Failed</th>
                        <th>HSM Seen</th>
                        <th>Session Send</th>
                        <th>Session Delivered</th>
                        <th>Session Failed</th>
                        <th>Session Seen</th>
                        <th>Download</th>
                    </tr>
                </thead>
                <tbody id="">
                    <tr>
                        <td>0176</td>
                        <td>1</td>
                        <td>0</td>
                        <td>0</td>
                        <td>1</td>
                        <td>0</td>
                        <td>1</td>
                        <td>0</td>
                        <td>1</td>
                        <td className="download_icon"><button><img src={Import} alt="img"/></button></td>
                    </tr>
                </tbody>
                <tfoot>
                    <td>Total</td>
                    <td>1</td>
                    <td>0</td>
                    <td>0</td>
                    <td>1</td>
                    <td>0</td>
                    <td>1</td>
                    <td>0</td>
                    <td>1</td>
                    <td></td>
                </tfoot>
            </table>
        </div>
    </div>
</div>

      
    </>
  )
}

export default WbDealerwiseMisReport
