import React, { useEffect, useState } from "react";
// import AddIcon from "../Assets/images/plus.png";
import deleteIcon from "../Assets/images/delete.png";
import "../css/chatbot.css";
// import axios from "axios";
// import helper from '../axios';
import { apiCall } from '../services/authServieces';

import DocIcon from '../Assets/images/document-icon.png'
import ImageIcon from '../Assets/images/image-icon.png'
import AudioIcon from '../Assets/images/music.png'
import VideoIcon from '../Assets/images/video-icon.png'

// const base_url = helper.api_url;

const Chatboat = () => {
  const [options, setOptions] = useState([]);
  // const [stage, setStage] = useState("");
  const [title, setTitle] = useState("");
  const [question, setQuestion] = useState("");
  const [qType, setQType] = useState("TEXT");
  const [answer, setAnswer] = useState("");
  const [tableData, setTableData] = useState([]);
  const [errors, setErrors] = useState({ question: "", answer: "" });
  const [getBotId, SetBotId] = useState('BotIdexample');
  const [getBotname, setBotname] = useState('Botnameexample');
  const [getcreateDate, setCreateddate] = useState('Botdateexample');
  const [dataUpdated, setDataUpdated] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Wel_101");
  const [root, SetRoot] = useState('');
  const [fileOptions, setFileOptions] = useState(null);
  const [fileData, setFileData] = useState("");
  const [ansFrom,setansFrom]=useState("");

  const fetchData = async (parent_id) => {
    if (!parent_id) return;

    const storedBotData = localStorage.getItem('botData');
    if (storedBotData) {
      const { botId, botName, created_date } = JSON.parse(storedBotData);
      const body = {
        parent_id,
        bot_id: botId
      };

      try {
        const response = await apiCall({ endpoint: 'api/get-bot-que-ans', method: 'post', payload: body });
        setTableData(response.data || []);

        const body1 = {
          question_id: parent_id
        };
        const response1 = await apiCall({ endpoint: 'api/get-title-root-path', method: 'post', payload: body1 });
        SetRoot(response1.data[0]?.hierarchy || '');

        SetBotId(botId);
        setBotname(botName);
        setCreateddate(created_date);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const fetchDropDownData = async () => {
    const storedBotData = localStorage.getItem('botData');
    if (storedBotData) {
      const { botId, botName, created_date, userId, businessNo, adminId } = JSON.parse(storedBotData);
      const body = {
        bot_id: botId,
        admin_id: adminId,
        created_by: userId,
        business_no: businessNo
      };

      try {
        const response = await apiCall({ endpoint: 'api/get-bot-que', method: 'post', payload: body });
        setOptions(response.data || []);
        SetBotId(botId);
        setBotname(botName);
        setCreateddate(created_date);
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    }
  };

  useEffect(() => {
    fetchDropDownData();
  }, []);

  useEffect(() => {
    if (selectedOption) {
      fetchData(selectedOption);
    }
  }, [selectedOption]);

  useEffect(() => {
    setFileOptions(qType === 'DOCUMENT' || qType === 'IMAGE' || qType === 'image');
  }, [qType]);


  const validateInputs = () => {
    let valid = true;
    const newErrors = { question: "", answer: "" };

    if (!question.trim()) {
      newErrors.question = "Question cannot be empty.";
      valid = false;
    }

    if (!answer.trim()) {
      newErrors.answer = "Answer cannot be empty.";
      valid = false;
    }
    if(!ansFrom.trim()){
      newErrors.ansFrom = "Answer from cannot be empty.";
      valid = false;

    }

    setErrors(newErrors);
    return valid;
  };

  const handleSave = async () => {
    if (validateInputs()) {
      const storedBotData = localStorage.getItem('botData');
      if (!storedBotData) return;

      // const { botId, botName, created_date, userId, businessNo, adminId } = JSON.parse(storedBotData);

      const { userId, businessNo, adminId } = JSON.parse(storedBotData);

      const newData = {
        admin_id: adminId,
        created_by: userId,
        business_no: businessNo,
        title,
        question,
        msg_type: qType,
        answer,
        bot_id: getBotId,
        parent_id: selectedOption,
        ansfrom:ansFrom
      };

      console.log(newData,'newData')
      
      const formData = new FormData();
      formData.append('file', fileData);
      formData.append('data', JSON.stringify(newData));

      try {
        const response = await apiCall({ endpoint: 'api/save-bot-que-ans', method: 'post', payload: formData });
        
        if (response.status === 200) {
          await fetchData(selectedOption);
          await fetchDropDownData();

          setQuestion("");
          setQType("TEXT");
          setAnswer("");
          setErrors({ question: "", answer: "" });

          console.log('Saved to DB:', response.data);
        } else {
          alert(response.message || 'Failed to save');
        }
      } catch (err) {
        console.error('Error:', err);
      }
    }
  };

  
  const handleUserFilechange = async (e) => {
    try {
      let data = e.target.files[0];
  
      if (data) {
        // Check if the file size is less than 2 MB (2 * 1024 * 1024 bytes)
        if (data.size < 2 * 1024 * 1024) {

          setFileData(data);
      
        } else {
          alert("File size should be less than 2 MB.");
          // Optionally, you can clear the file input
          e.target.value = null;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  

  const handleDeleteRow = async (id) => {
    try {
      const body = { id };
      const response = await apiCall({ endpoint: 'api/delete-bot-que-ans', method: 'post', payload: body });

      if (response.status === 200) {
        alert(response.message);
        setTableData((prevData) => prevData.filter((row) => row.id !== id));
      } else {
        console.error('Failed to delete:', response.data.error);
      }
    } catch (error) {
      console.error('Error deleting row:', error);
    }
  };

  return (
    <section id="Chatbot">
      <div className="container">
        <div className="Chatbot_header">
          <div className="Bot_id scrollable">
            <h5>
              Sequence: <span>{root}</span>
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="Chatbot_leftBar">
              <ul>
                <li>
                  <div className="Bot_group">
                    <label htmlFor="stage">Question Title</label>
                    <select
                      name="stage"
                      id="stage"
                      value={selectedOption}
                      onChange={(e) => setSelectedOption(e.target.value)}
                    >
                      {options.length === 0 ? (
                        <option>Welcome</option>
                      ) : (
                        <>
                          <option>---Select---</option>
                          {options.map((option, index) => (
                            <option key={index} value={option.question_id}>
                              {option.ques_title}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                  </div>
                </li>
                <li>
                  <div className="Bot_group">
                    <label htmlFor="Question">Enter Question Title</label>
                    <input
                      type="text"
                      name="Question"
                      id="Question"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Enter Your Question Title"
                    />
                    {errors.question && <span className="error">{errors.question}</span>}
                  </div>
                </li>
                <li>
                  <div className="Bot_group">
                    <label htmlFor="Question">Question</label>
                    <input
                      type="text"
                      name="Question"
                      id="Question"
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                      placeholder="Enter Your Question Here"
                    />
                    {errors.question && <span className="error">{errors.question}</span>}
                  </div>
                </li>
                <li>
                  <div className="Bot_group">
                    <label htmlFor="Qtype">Question Type</label>
                    <select
                      name="Qtype"
                      id="Qtype"
                      value={qType}
                      onChange={(e) => setQType(e.target.value)}
                    >
                      <option value="TEXT">Text</option>
                      <option value="DOCUMENT">Document</option>
                      <option value="IMAGE">Image</option>
                      {/* <option value="media">Media</option> */}
                    </select>
                  </div>
                </li>
                <li>
                  <div className="Bot_group">
                    <label htmlFor="AnsFrom">Answer From</label>
                    <select
                      name="AnsFrom"
                      id="AnsFrom"
                      value={ansFrom}
                      onChange={(e) => setansFrom(e.target.value)}
                    >
                      <option>---Select---</option>
                      <option value="Internal">Internal</option>
                      <option value="External">External</option>
                    </select>
                    {errors.ansFrom && <span className="error">{errors.ansFrom}</span>}
                  </div>
                </li>
                {fileOptions && (
                  <li>
                    <div className="Bot_group">
                      <label htmlFor="Answer">File</label>
                      <input
                        type="file"
                        name="Answer"
                        id="Answer"
                        onChange={handleUserFilechange} />
                    </div>
                  </li>
                )}
                <li>
                  <div className="Bot_group">
                    <label htmlFor="Answer">Answer</label>
                    <textarea
                      name="Answer"
                      id="Answer"
                      value={answer}
                      onChange={(e) => setAnswer(e.target.value)}
                      placeholder="Enter Your Answer Here"
                    ></textarea>
                    {errors.answer && <span className="error">{errors.answer}</span>}
                  </div>
                </li>
                <li>
                  <button type="button" className="Bot_saveBtn" onClick={handleSave}>
                    Save
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="Chatboat_rightBar">
              <div className="d-flex justify-content-between px-2">
                <div>Bot ID: <span>{getBotId}</span></div>
                <div>Date: <span>{getcreateDate}</span></div>
              </div>
              <div className="BotTable_contain">
                <table className="Bot_table">
                  <thead>
                    <tr>
                      <th>Ques_Name</th>
                      <th>Ques_Type</th>
                      <th>Media</th>
                      <th className="Answer_th">Answer</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
  {tableData && tableData.length > 0 ? (
    tableData.map((row) => {
      // Check if media_url is null
      if (!row.media_url) {
        return (
          <tr key={row.id}>
            <td>{row.question}</td>
            <td>{row.msg_type}</td>
            <td></td> {/* Empty column if media_url is null */}
            <td>{row.answer}</td>
            <td>
              <button onClick={() => handleDeleteRow(row.id)} className="ChatBotDeleteBtn">
                <img src={deleteIcon} alt="Delete" />
              </button>
            </td>
          </tr>
        );
      }

      // Extract file extension from the media_url
      const fileExtension = row.media_url.split('.').pop().toLowerCase();

      // Function to determine if the media is an image
      const isImage = (ext) => ['jpg', 'jpeg', 'png', 'gif'].includes(ext);
      
      // Function to determine if the media is an audio
      const isAudio = (ext) => ['mp3', 'wav', 'ogg'].includes(ext);
      
      // Function to determine if the media is a video
      const isVideo = (ext) => ['mp4', 'avi', 'mov', 'mkv'].includes(ext);

      return (
        <tr key={row.id}>
          <td>{row.question}</td>
          <td>{row.msg_type}</td>
          <td>
            <a href={`${row.media_url}`} target="_blank" rel="noopener noreferrer">
              {isImage(fileExtension) ? (
                <img 
                  src={ImageIcon} 
                  alt="Image Media" 
                  style={{ width: '25px', height: '25px' }} 
                  loading="lazy"
                />
              ) : isAudio(fileExtension) ? (
                <img 
                  src={AudioIcon}  // Replace with an actual audio icon path
                  alt="Audio File" 
                  style={{ width: '25px', height: '25px' }}
                  loading="lazy"
                />
              ) : isVideo(fileExtension) ? (
                <img 
                  src={VideoIcon}  // Replace with an actual video icon path
                  alt="Video File" 
                  style={{ width: '25px', height: '25px' }}
                  loading="lazy"
                />
              ) : (
                <img 
                  src={DocIcon}  // Default icon for documents like PDFs
                  alt="Doc Icon" 
                  style={{ width: '25px', height: '25px' }}
                  loading="lazy"
                />
              )}
            </a>
          </td>
          <td>{row.answer}</td>
          <td>
            <button onClick={() => handleDeleteRow(row.id)}>
              <img src={deleteIcon} alt="Delete" />
            </button>
          </td>
        </tr>
      );
    })
  ) : (
    <tr>
      <td colSpan="5">No data available</td>
    </tr>
  )}
</tbody>


                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Chatboat;
