import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register necessary components in Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ChartComponent = ({ title, chartData }) => {
  const getLast10DaysLabels = () => {
    let labels = [];
    let currentDate = new Date();

    for (let i = 0; i < 10; i++) {
      let date = new Date();
      date.setDate(currentDate.getDate() - i);
      
      let formattedDate = date.toLocaleString('default', { month: 'short', day: '2-digit' });
      labels.unshift(formattedDate);
    }

    return labels;
  };

  const dynamicLabels = getLast10DaysLabels();

  // Chart data
  const data = {
    labels: dynamicLabels, 
    datasets: [
      {
        label: 'Read',
        data: chartData.read,
        backgroundColor: '#00acc1',
        borderWidth: 1
      },
      {
        label: 'Failed',
        data: chartData.failed,
        backgroundColor: '#e53935',
        borderWidth: 1
      },
      {
        label: 'Delivered',
        data: chartData.delivered,
        backgroundColor: '#8cc751',
        borderWidth: 1
      }
    ]
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: 100, 
        ticks: {
          stepSize: 25,
          callback: function (value) {
            return value + '%';  // Display percentages on y-axis
          }
        }
      }
    }
  };

  return (
    <>
      <h4>{title}</h4>
      <div className="Bar_contain">
        <Bar data={data} options={options} />
      </div>
    </>
  );
};

export default ChartComponent;
