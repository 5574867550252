import { React, useState, useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "../css/wb_add_template.css";
import Smile from "../Assets/images/smile.png";
import Bpng from "../Assets/images/b.png";
import Italic from "../Assets/images/italic.png";
import User from "../Assets/images/user.png";
import pdfIcon from "../Assets/images/pdf.png";
import videoIcon from "../Assets/images/video-icon.png";
import imageIcon from "../Assets/images/image-icon.png";
import locationIcon from "../Assets/images/location.png";
import carouselIcon from "../Assets/images/carousel.png";
import CloseIcon from "../Assets/images/close_icon.png";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { prepareFormData, postTemplate } from "../services/templateService";
import ShowSnackBar from "../components/snackBar";
import languages from "@cospired/i18n-iso-languages";
import EmojiPicker from "emoji-picker-react";
import SplitButton from "../components/SplitGroupBtn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ReplyIcon from "@mui/icons-material/Reply";
import LaunchIcon from "@mui/icons-material/Launch";
import { apiCall } from "../services/authServieces";
import { validationSchema } from "../utils/formValidation";
import { ClipLoader } from "react-spinners";

languages.registerLocale(require("@cospired/i18n-iso-languages/langs/en.json"));

const WbAddTemplate = () => {
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [maxCharacters] = useState(1024);
  const [charactersUsed, setCharactersUsed] = useState(0);
  const [templateName, isTemplateName] = useState("");
  const [templateTextData, setTemplateTextData] = useState("");
  const [templateType, isTemplateType] = useState("");
  const [file, setFile] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [selectedVar, setSelectedVar] = useState("");
  const [variableValue, setVariableValue] = useState("");
  const [templateMessage, setTemplateMessage] = useState("");
  const [carousalTextMessages, setCarousalTextMessages] = useState({});
  const [buttonTextForCarousals, setButtonTextForCarousals] = useState({});
  const [domainForCarousals, setDomainForCarousals] = useState({});
  const [quickReplyForCarousals, setQuickReplyForCarousals] = useState({});
  const [CarousalFile, setCarousalFile] = useState(null);
  const [variables, setVariables] = useState({});
  const [headerVal, setHeaderVal] = useState("");
  const [footerVal, setFooterVal] = useState("");
  const [headerMax, setHeaderMax] = useState(0);
  const [footerMax, setFooterMax] = useState(0);
  const [redirectLink, setRedirectLink] = useState(false);
  const [isCategory, setIsCategory] = useState("Marketing");
  const [isLanguage, setIsLanguage] = useState("English");
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: true,
    message: "",
  });
  const [selectedMedia, setSelectedMedia] = useState("");
  const [CarousalMedia, setCarousalMedia] = useState("");
  const textareaRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null); // Create a ref for the emoji picker
  const [showCallToAction, setShowCallToAction] = useState(false);
  const [showCallPhone, setShowCallPhone] = useState(false);
  const [showQuickReply, setShowQuickReply] = useState(false);
  const [visitWebsiteText, setVisitWebsiteText] = useState("");
  const [domain, setDomain] = useState("");
  const [additionalUrls, setAdditionalUrls] = useState([]);
  const [phoneNumberText, setPhoneNumberText] = useState("");
  const [phone, setPhone] = useState("");
  const [quickReply, setQuickReply] = useState("");
  const [isNewQuickReply, setIsNewQuickReply] = useState([]);
  const [totalButtons, setTotalButtons] = useState(0);
  const [inputFields, setInputFields] = useState([]);
  const [formattedMessage, setFormattedMessage] = useState("");
  const [placeholderVariables, setPlaceHolderVariables] = useState([]);
  let navigate=useNavigate();

  // Handler to update the carousal text dynamically based on card id
  const handleCarousalTextChange = (e, id) => {
    setCarousalTextMessages({
      ...carousalTextMessages,
      [id]: e.target.value,
    });
  };

  // Handler to update the button text dynamically based on card id
  const handleButtonTextChange = (e, id) => {
    setButtonTextForCarousals({
      ...buttonTextForCarousals,
      [id]: e.target.value,
    });
  };

  // Handler to update the domain dynamically based on card id
  const handleDomainChange = (e, id) => {
    setDomainForCarousals({
      ...domainForCarousals,
      [id]: e.target.value,
    });
  };

  // Handler to update the quick reply dynamically based on card id
  const handleQuickReplyChange = (e, id) => {
    setQuickReplyForCarousals({
      ...quickReplyForCarousals,
      [id]: e.target.value,
    });
  };


  const languages = [
    { value: "", label: "--Select--" },
    { value: "af", label: "Afrikaans" },
    { value: "sq", label: "Albanian" },
    { value: "ar", label: "Arabic" },
    { value: "az", label: "Azerbaijani" },
    { value: "bn", label: "Bengali" },
    { value: "bg", label: "Bulgarian" },
    { value: "ca", label: "Catalan" },
    { value: "zh_CN", label: "Chinese (CHN)" },
    { value: "zh_HK", label: "Chinese (HKG)" },
    { value: "zh_TW", label: "Chinese (TAI)" },
    { value: "hr", label: "Croatian" },
    { value: "cs", label: "Czech" },
    { value: "da", label: "Danish" },
    { value: "nl", label: "Dutch" },
    { value: "en", label: "English" },
    { value: "en_GB", label: "English (UK)" },
    { value: "en_US", label: "English (US)" },
    { value: "et", label: "Estonian" },
    { value: "fil", label: "Filipino" },
    { value: "fi", label: "Finnish" },
    { value: "fr", label: "French" },
    { value: "de", label: "German" },
    { value: "el", label: "Greek" },
    { value: "gu", label: "Gujarati" },
    { value: "he", label: "Hebrew" },
    { value: "hi", label: "Hindi" },
    { value: "hu", label: "Hungarian" },
    { value: "id", label: "Indonesian" },
    { value: "ga", label: "Irish" },
    { value: "it", label: "Italian" },
    { value: "ja", label: "Japanese" },
    { value: "kn", label: "Kannada" },
    { value: "kk", label: "Kazakh" },
    { value: "ko", label: "Korean" },
    { value: "lo", label: "Lao" },
    { value: "lv", label: "Latvian" },
    { value: "lt", label: "Lithuanian" },
    { value: "mk", label: "Macedonian" },
    { value: "ms", label: "Malay" },
    { value: "mr", label: "Marathi" },
    { value: "nb", label: "Norwegian" },
    { value: "fa", label: "Persian" },
    { value: "pl", label: "Polish" },
    { value: "pt_BR", label: "Portuguese (BR)" },
    { value: "pt_PT", label: "Portuguese (POR)" },
    { value: "pa", label: "Punjabi" },
    { value: "ro", label: "Romanian" },
    { value: "ru", label: "Russian" },
    { value: "sr", label: "Serbian" },
    { value: "sk", label: "Slovak" },
    { value: "sl", label: "Slovenian" },
    { value: "es", label: "Spanish" },
    { value: "es_AR", label: "Spanish (ARG)" },
    { value: "es_ES", label: "Spanish (SPA)" },
    { value: "es_MX", label: "Spanish (MEX)" },
    { value: "sw", label: "Swahili" },
    { value: "sv", label: "Swedish" },
    { value: "ta", label: "Tamil" },
    { value: "te", label: "Telugu" },
    { value: "th", label: "Thai" },
    { value: "tr", label: "Turkish" },
    { value: "uk", label: "Ukrainian" },
    { value: "ur", label: "Urdu" },
    { value: "uz", label: "Uzbek" },
    { value: "vi", label: "Vietnamese" },
    { value: "ka", label: "Georgian" },
    { value: "ha", label: "Hausa" },
    { value: "rw_RW", label: "Kinyarwanda" },
    { value: "ky_KG", label: "Kyrgyz (Kyrgyzstan)" },
    { value: "ml", label: "Malayalam" },
  ];

  const cancelNavigate = useNavigate();

  const handleInputChange = (index, event) => {
    const newVariables = [...placeholderVariables];
    newVariables[index] = event.target.value;
    setPlaceHolderVariables(newVariables);
  };

  const handleOptionSelect = (option) => {
    if (option === "Visit Website") {
      if (totalButtons < 10) {
        setShowCallToAction(true);
        setTotalButtons(totalButtons + 1);
        handleAddUrl();
      } else {
        setSnackBar({
          open: true,
          severity: false,
          message: "Only 10 Buttons are Allowed",
        });
      }
    } else if (option === "Call Phone Number") {
      if (totalButtons < 10) {
        setShowCallPhone(true);
        setTotalButtons(totalButtons + 1);
      } else {
        setSnackBar({
          open: true,
          severity: false,
          message: "Only 10 Buttons are Allowed",
        });
      }
    } else if (option === "Quick Reply") {
      if (totalButtons < 10) {
        setShowQuickReply(true);
        setTotalButtons(totalButtons + 1);
        handleNewReply();
      } else {
        setSnackBar({
          open: true,
          severity: false,
          message: "Only 10 Buttons are Allowed",
        });
      }
    }
  };
  const handleAddUrl = () => {
    if (totalButtons < 10) {
      setAdditionalUrls([
        ...additionalUrls,
        { id: Date.now(), buttonText: "", domain: "" },
      ]);
      setTotalButtons(totalButtons + 1);
    } else {
      setSnackBar({
        open: true,
        severity: false,
        message: "Only 10 Buttons are Allowed",
      });
    }
  };
  const handleNewReply = () => {
    if (totalButtons < 10) {
      setIsNewQuickReply([
        ...isNewQuickReply,
        { id: Date.now(), buttonText: "" },
      ]);
      setTotalButtons(totalButtons + 1);
    } else {
      setSnackBar({
        open: true,
        severity: false,
        message: "Only 10 Buttons are Allowed",
      });
    }
  };
  const handleRemoveUrl = (id) => {
    setAdditionalUrls(additionalUrls.filter((url) => url.id !== id));
    setTotalButtons(totalButtons - 1);
  };

  const onEmojiClick = (emojiObject) => {
    setTemplateMessage((prevMessage) => prevMessage + emojiObject.emoji);
    setShowPicker(false); // Optionally close the picker after selection
  };
    // Function to handle clicks outside the emoji picker
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };
    // Use effect to add/remove the event listener
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  const checkValidInp = (e) => {
    let inpVal = e.target.value;
    inpVal = inpVal.toLowerCase().replace(/\s/g, "_");
    const regExpress = /^[0-9a-z_]*$/;
    if (regExpress.test(inpVal)) {
      isTemplateName(inpVal);
    }
  };

  const checkValidTemplateTextData = (e) => {
    let inpVal = e.target.value;
      setTemplateTextData (inpVal);
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelectedVar(value);

    if (value === "") {
      setTemplateMessage("");
      setVariables({});
    } else {
      setTemplateMessage((prevValue) => `${prevValue}{{${value}}} `);
    }

    if (value && !inputFields.includes(value)) {
      setInputFields((prevFields) => [...prevFields, value]);
    }
  };

  const handleTextareaChange = (e) => {
    const newValue = e.target.value;
    setTemplateMessage(newValue);

    setCharactersUsed(newValue.length);
    if (newValue.length > 1023) {
      setSnackBar({
        open: true,
        severity: false,
        message: "Character limit of 1024 reached for message!",
      });
      return; 
    }

    const regex = /\{\{(.*?)\}\}/g;
    const foundVars = [...newValue.matchAll(regex)].map((match) => match[1]);

    setInputFields((prevFields) =>
      prevFields.filter((field) => foundVars.includes(field))
    );

    setVariables((prevVars) => {
      const updatedVars = { ...prevVars };
      Object.keys(updatedVars).forEach((key) => {
        if (!foundVars.includes(key)) {
          delete updatedVars[key];
        }
      });
      return updatedVars;
    });
    setPlaceHolderVariables((prevPlaceholders) => {
      return prevPlaceholders.filter((_, index) => {
        return foundVars.includes((index + 1).toString());
      });
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileSize = file.size;
    const maxSize = 2 * 1024 * 1024;

    const fileUrl = URL.createObjectURL(file);
    if (fileSize > maxSize) {
      setSnackBar({
        open: true,
        severity: false,
        message: `Media Size Can't Exceed limit of 2mb`,
      });
      return (event.target.value = "");
    }
    if (file) {
      setFile(file);
      const url = URL.createObjectURL(file);
      setFileURL(url);
      setFileType(file.type);
    }
  };

  const handleFileCarousalChange = (event) =>{
    const file = event.target.files[0];
    const fileSize = file.size;
    const maxSize = 2 * 1024 * 1024;

    const fileUrl = URL.createObjectURL(file);
    if (fileSize > maxSize) {
      setSnackBar({
        open: true,
        severity: false,
        message: `Media Size Can't Exceed limit of 2mb`,
      });
      return (event.target.value = "");
    }

    if (file) {
      setCarousalFile(file)
    }
  }

  const isImage = fileType?.startsWith("image");
  const isVideo = fileType?.startsWith("video");
  const isDocument = fileType?.startsWith("application");
  const isLocation = fileType?.startsWith("location");
  const isCarousel = fileType?.startsWith("image") || fileType?.startsWith("video");

  const handleMouseUp = () => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    const selectedText = templateMessage.slice(start, end);

    if (selectedText) {
      const modifiedText =
        templateMessage.slice(0, start) +
        `*${selectedText}*` +
        templateMessage.slice(end);
      setTemplateMessage(modifiedText);
    }
  };

  const handleMouseUpItalic = () => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    const selectedText = templateMessage.slice(start, end);

    if (selectedText) {
      const modifiedText =
        templateMessage.slice(0, start) +
        `_${selectedText}_` +
        templateMessage.slice(end);
      setTemplateMessage(modifiedText);
    }
  };

  const handleSubmit = async (e) => {


    if (selectedMedia === "Carousel") {
      const allFieldsFilled = cards.every((card) => {
        const text = carousalTextMessages[card.id] || '';
        const buttonText = buttonTextForCarousals[card.id] || '';
        const domain = domainForCarousals[card.id] || '';
        const quickReply = quickReplyForCarousals[card.id] || '';
    
        if (!text.trim() || !buttonText.trim() || !quickReply.trim() || !domain.trim()) {
          setSnackBar({
            open: true,
            severity: false,
            message: "Please fill all the fields in the carousel card!",
          });
          return false;
        }
    
        if (!validateURL(domain)) {
          setSnackBar({
            open: true,
            severity: false,
            message: "Please enter a valid URL in the domain field.",
          });
          return false;
        }
    
        return true;
      });
    
      if (!allFieldsFilled) {
        return; 
      }
    }
    
    if (domain) {
      if (!validateURL(domain)) {
        setSnackBar({
          open: true,
          severity: false,
          message: "Please enter a valid URL.",
        });
        return;
      }
    }

    if(footerVal){
      if(footerVal.length > 60){
        setSnackBar({
          open: true,
          severity: false,
          message: "The Footer field can't have more than 60 characters.",
        });
        return; 
      }
    }

    e.preventDefault();

    setLoading(true);
    const formData = {
      templateName,
      isCategory,
      isLanguage,
      templateType,
      selectedMedia,
      fileType,
      fileURL,
      file,
      selectedVar,
      variableValue,
      templateMessage,
      footerVal,
      headerVal,
      maxCharacters,
      charactersUsed,
      variables,
      visitWebsiteText,
      domain,
      additionalUrls,
      phoneNumberText,
      phone,
      quickReply,
      isNewQuickReply,
      inputFields,
      placeholderVariables,
      templateTextData,
      CarousalMedia,
      CarousalFile,
      carousalTextMessages,
      buttonTextForCarousals,
      domainForCarousals,
      quickReplyForCarousals
    };

    try {
      let validatedObj = await validationSchema.validate(formData, {
        abortEarly: false,
      });


      const res = await postTemplate({
        payload: prepareFormData(validatedObj),
      });
      if (res.success) {
        setLoading(false);
        setSnackBar({
          open: true,
          severity: res?.success,
          message: res?.message,
        });
        setTimeout(() => {
          setRedirectLink(true);
        }, 1000);
      }
    } catch (err) {
      setLoading(false);
      if (err.name === "ValidationError") {
        const validationErrors = err.inner.map((error) => error.message);
        setSnackBar({
          open: true,
          severity: false,
          message: validationErrors.join(", "),
        });
      } else {
        setSnackBar({
          open: true,
          severity: err?.response?.data?.success || false,
          message: err?.response?.data?.message || "An error occurred",
        });
      }
    }
  };

  const validateURL = (url) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|" + // domain name and extension
        "localhost|" + // OR localhost
        "\\d{1,3}(\\.\\d{1,3}){3})" + // OR IPv4 address
        "(\\:\\d+)?" + // optional port
        "(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)*" + // path
        "(\\?[;&a-zA-Z0-9@:%_\\+.~#?&//=]*)?" + // query string
        "(\\#[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?$",
      "i"
    );
    return !!urlPattern.test(url);
  };

  // user info
  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const res = await apiCall({
          endpoint: "templates/getUserDetails",
          method: "GET",
        });

        if (res?.success) {
          setUserInfo(res.userName);
        }
      } catch (error) {
        setSnackBar({
          open: true,
          severity: "error",
          message: error?.response?.data?.message || "An error occurred",
        });
      }
    };

    getUserInfo();
  }, []);

  useEffect(() => {
    const convertToHTML = (message) => {
      let html = message;

      html = html.replace(/\*(.*?)\*/g, "<strong>$1</strong>");

      html = html.replace(/_(.*?)_/g, "<em>$1</em>");

      return html;
    };
    setFormattedMessage(convertToHTML(templateMessage));
  }, [templateMessage]);

  if (redirectLink) {
    navigate(`/wbtemplate`);
  }


  const handleHeaderChange = (e) => {
    
    const value = e.target.value;
    setHeaderVal(value);
    setHeaderMax(value.length);
    if (value.length > 59) {
      setSnackBar({
        open: true,
        severity: false,
        message: "Character limit of 60 reached for header!",
      });
      return; 
    }
  };
  const handleFooterChange = (e) => {
    const value = e.target.value;
    setFooterVal(value);
    setFooterMax(value.length);
    if (value.length > 59) {
      setSnackBar({
        open: true,
        severity: false,
        message: "Character limit of 60 reached for footer!",
      });
      return;
    }
  };

  // carousel cards start
  const [cards, setCards] = useState([]);

  const addCard = () => {
    if (cards.length < 10) {
      setCards([...cards, { id: cards.length }]);
    }else{
      
      setSnackBar({
        open: true,
        severity: false,
        message: "Cards limit of 10 reached!",
      });
    }
  };

  const removeCard = (id) => {
    setCards(cards.filter(card => card.id !== id));
  };
    // carousel cards start

    const [showAll, setShowAll] = useState(false); // State to manage button visibility
    const ButtonsArray = [];
     // Mapping through additionalUrls
  if (additionalUrls && additionalUrls.length > 0) {
    additionalUrls.forEach((button, index) => {
      ButtonsArray.push(
        <button 
          key={`url-${index}`} 
          type="button" 
          className={`btn commonBtn mt-2 ${phoneNumberText.length > 0 || isNewQuickReply.length > 0 ? '' : (index === additionalUrls.length - 1 && (additionalUrls.length % 2 !== 0) ? 'w-100' : '')}`}
        >
          <LaunchIcon /> {button.buttonText || "Button Text"}
        </button>
      );
    });
  }

  // Phone Number Button
  if (phoneNumberText) {
    ButtonsArray.push(
      <button 
        key="phone" 
        className={`btn commonBtn mt-2 ${additionalUrls.length % 2 !== 0 ? ' ' : isNewQuickReply.length > 0 ? '' : 'w-100'}`}
      >
        <LocalPhoneIcon /> {phoneNumberText}
      </button>
    );
  }

  // Quick Reply Buttons
  if (isNewQuickReply && isNewQuickReply.length > 0) {
    isNewQuickReply.forEach((button, index) => {
      ButtonsArray.push(
        <a
          key={`reply-${index}`}
          href={button.domain || "#"}
          className={`btn commonBtn mt-2 ${
            phoneNumberText.length > 0 
              ? (additionalUrls.length % 2 === 0 
                ? (index === isNewQuickReply.length - 1 && isNewQuickReply.length % 2 === 0 ? 'w-100' : '') 
                : (index === isNewQuickReply.length - 1 && isNewQuickReply.length % 2 !== 0 ? 'w-100' : ''))
              : (additionalUrls.length % 2 === 0 
                ? (index === isNewQuickReply.length - 1 && isNewQuickReply.length % 2 !== 0 ? 'w-100' : '') 
                : (index === isNewQuickReply.length - 1 && isNewQuickReply.length % 2 === 0 ? 'w-100' : ''))
          }`}
        >
          <ReplyIcon /> {button.buttonText || "Button Text"}
        </a>
      );
    });
  }


  



  return (
    <>
      <div className="WB_add_template_contain">
        <h4 className="Head_title">Add Template</h4>
        <div className="Customize_card">
          <div className="row">
            <div className="col-lg-7">
              <div className="row gy-3">
                <div className="col-lg-4 col-12">
                  <div className="form_group">
                    <label htmlFor="tname">
                      Template Name <span className="required_icon">*</span>
                    </label>
                    <input
                      type="text"
                      id="tname"
                      placeholder="Enter Template Name"
                      onChange={checkValidInp}
                      value={templateName}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="form_group">
                    <label htmlFor="Category">
                      Category <span className="required_icon">*</span>
                    </label>
                    <select
                      id="Category"
                      value={isCategory}
                      onChange={(e) => {
                        setIsCategory(e.target.value);
                      }}
                    >
                      <option value="Marketing">Marketing</option>
                      <option value="Utility">Utility</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="form_group">
                    <label htmlFor="Language">
                      Language <span className="required_icon">*</span>
                    </label>
                    <select
                      id="Language"
                      value={isLanguage}
                      onChange={(e) => setIsLanguage(e.target.value)}
                    >
                      {languages.map((language) => (
                        <option key={language.value} value={language.value}>
                          {language.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <p className="highlight">
                    Template name can only contain lowercase alphanumeric
                    characters and underscores ( _ ). No other characters or
                    white space are allowed.
                  </p>
                </div>
              </div>
              <div className="WB_template_header">
                <h5 className="commonHeading">Header Optional</h5>
                <p>
                  Add a title or choose which type of media you'll use htmlFor
                  this header. your title can't include more than one variable.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-6 col-12 margin_top0">
                  <div className="form_group">
                    <label htmlFor="Type">
                      Template Type <span className="required_icon">*</span>
                    </label>
                    <select
                      id="Type"
                      value={templateType}
                      onChange={(e) => {
                        isTemplateType(e.target.value);
                      }}
                    >
                      <option value="">None</option>
                      <option value="Text">Text </option>
                      <option value="Media">Media</option>
                    </select>
                  </div>
                  <div className="row">
                  <div
                      className="col-lg-12 margin_top0"
                      style={{
                        display: templateType === "Text" ? "block" : "none",
                      }}
                    >
                      <div className="form_group">
                        <label htmlFor="Text">Header  <span className="required_icon">*</span></label>
                        <input
                          type="text"
                          id="templateTypeHeader"
                          placeholder="Enter your label"
                          value={headerVal}
                          onChange={handleHeaderChange}
                          maxLength={60}
                        />
                         <span className="CharLimit">Max Characters : {headerMax}/60</span>
                      </div>
                    </div>
                    <div
                      className="col-lg-12 margin_top0"
                      style={{
                        display: templateType === "Media" ? "block" : "none",
                      }}
                    >
                      <div className="form_group">
                        <label htmlFor="mediaType">
                          Media Type <span className="required_icon">*</span>
                        </label>

                        <label htmlFor="Document" className="radio-inline">
                          <input
                            type="radio"
                            id="Document"
                            name="val"
                            value="Document"
                            checked={selectedMedia === "Document"}
                            onChange={(event) => {
                              setSelectedMedia(event.target.value);
                            }}
                          />
                          Document
                        </label>

                        <label htmlFor="Images" className="radio-inline">
                          <input
                            type="radio"
                            id="Images"
                            name="val"
                            value="Image"
                            checked={selectedMedia === "Image"}
                            onChange={(event) => {
                              setSelectedMedia(event.target.value);
                            }}
                          />
                          Images
                        </label>

                        <label htmlFor="Video" className="radio-inline">
                          <input
                            type="radio"
                            id="Video"
                            name="val"
                            value="Video"
                            checked={selectedMedia === "Video"}
                            onChange={(event) => {
                              setSelectedMedia(event.target.value);
                            }}
                          />
                          Video
                        </label>
                        <label htmlFor="Location" className="radio-inline">
                          <input
                            type="radio"
                            id="Location"
                            name="val"
                            value="Location"
                            checked={selectedMedia === "Location"}
                            onChange={(event) => {
                              setSelectedMedia(event.target.value);
                            }}
                          />
                          Location
                        </label>
                        <label htmlFor="Carousel" className="radio-inline">
                          <input
                            type="radio"
                            id="Carousel"
                            name="val"
                            value="Carousel"
                            checked={selectedMedia === "Carousel"}
                            onChange={(event) => {
                              setSelectedMedia(event.target.value);
                            }}
                          />
                          Carousel
                        </label>
                        <p className="highlight">
                          Image: jpeg, png; Video: mp4; Document: .pdf, Maximum
                          file size should be 2MB
                        </p>
                      </div>
                      <div className="form_group"  style={{
                        display: selectedMedia !== "Carousel" ? (selectedMedia !== "Location" ? "block" : "none") : "none"
                      }}>
                        <label htmlFor="Media Upload">Upload Media</label>
                        <input
                          type="file"
                          placeholder="Upload Media"
                          onChange={handleFileChange}
                          name="media"
                        />
                      </div>
                    </div>
                 
                  </div>
                </div>
                <div className="col-lg-6 col-12 margin_top0">
                  {/* <div className="WB_template_header">
                      <h5 className="commonHeading">Body</h5>
                    </div> */}
                  <div className="form_group margin_top0">
                    <label htmlFor="Placeholder">
                      Variable <span className="required_icon">*</span>
                    </label>
                    <select
                      id="Placeholder"
                      value={selectedVar}
                      onChange={handleSelectChange}
                    >
                      <option value="">Select</option>
                      <option value="1">Var 1 </option>
                      <option value="2">Var 2</option>
                      <option value="3">Var 3</option>
                      <option value="4">Var 4</option>
                      <option value="5">Var 5</option>
                      <option value="6">Var 6</option>
                      <option value="7">Var 7</option>
                      <option value="8">Var 8</option>
                      <option value="9">Var 9</option>
                      <option value="10">Var 10</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 mt-3">
                  <div className="form_group">
                    <label htmlFor="Message">
                      Message: <span className="required_icon">*</span>
                    </label>
                    <span className="message_icons">
                    <button
        className="editingBtn"
        onClick={() => {
          setShowPicker((val) => !val);
          setTemplateMessage((prevMessage) => prevMessage );
        }}
        maxLength={1024}
      >
        <img src={Smile} alt="img" />
      </button>

      {showPicker && (
        <div
          className="emoji-picker-container"
          style={{ position: 'absolute', zIndex: 1000 }}
          ref={pickerRef} // Attach the ref to the emoji picker
        >
          <EmojiPicker onEmojiClick={onEmojiClick} />
        </div>
      )}
                      <button
                        className="B_icon_btn editingBtn"
                        onMouseUp={handleMouseUp}
                      >
                        <img src={Bpng} alt="img" />
                      </button>
                      <button className="editingBtn">
                        <img
                          src={Italic}
                          alt="img"
                          onMouseUp={handleMouseUpItalic}
                        />
                      </button>
                    </span>
                    <textarea
                      rows="3"
                      maxLength={1024}
                      name="Message"
                      id="Message"
                      value={templateMessage}
                      onChange={handleTextareaChange}
                      placeholder="Enter Template Message"
                      ref={textareaRef}
                    ></textarea>
                  </div>
                  <div className="List_card">
                    <span>
                     1
                      SMS
                    </span>

                    <span>
                      {" "}
                      Max Characters : <span>{charactersUsed}/{maxCharacters}</span>{" "}
                    </span>
                  </div>

                  <div className="col-lg-12 mt-3">
                    {inputFields.length > 0 && (
                      <>
                        <h6>Place Holder Value</h6>
                        {inputFields.map((field, index) => (
                          <div key={index} className="form_group">
                            <input
                              type="text"
                              className="mb-2"
                              placeholder={`Enter Sample Value for Body {{${field}}}`}
                              value={placeholderVariables[index] || ""}
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                            />
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
                <div className="col-12">
                <div
                      className="margin_top0 pt-2 CarouselContain"
                      style={{
                        display: selectedMedia === "Carousel" ? "block" : "none",
                      }}
                    >
                      <div className="form_group mt-0">
                        
                        <div className="d-flex justify-content-between align-items-center mb-2 ">
                        <label htmlFor="Text">Carousel <span className="required_icon">*</span></label>
                          <button className="AddCarouselBtn bg_green" onClick={addCard}>Add Carousel Cards</button></div>
                        <div className=" CarouselRadioContain">
                          <span>Media Type :</span>
                        <label htmlFor="Image" className="radio-inline">
                          <input
                            type="radio"
                            id="Image"
                            name="CarousalRadioBtn"
                            value="Image"
                            checked={CarousalMedia === "Image"}
                            onChange={(event) => {
                              setCarousalMedia(event.target.value);
                            }}
                          />
                          Image
                        </label>
                        <label htmlFor="Video" className="radio-inline">
                          <input
                            type="radio"
                            id="Video"
                            name="CarousalRadioBtn"
                            value="Video"
                            checked={CarousalMedia === "Video"}
                            onChange={(event) => {
                              setCarousalMedia(event.target.value);
                            }}
                          />
                          Video
                        </label>
                        </div>
                        <div className=" CarouselRadioContain mt-3">
                          <span>Media </span> 
                          <input type="file" onChange={handleFileCarousalChange} />
                        </div>
                        <p className="highlight mt-1">Image: jpeg, png; Video: mp4; Document: .pdf, Maximum file size should be 2MB</p>
                        {cards.length > 0 && (
                        <div className="CarouselCardsContain">

                        {cards.map((card, index) => (
                          <div className="CarouselCard" key={card.id}>
                            <div className="CountCloseContain">
                              <span>{index + 1}</span>
                              <button onClick={() => removeCard(card.id)}>
                                <img src={CloseIcon} alt="close" />
                              </button>
                            </div>
                            <div className="CarouselFormGroup">
                              <label htmlFor={`Body-${card.id}`}>Body:</label>
                              <div className="ImojiContain">
                                <button><img src={Smile} alt="smile" /></button>
                                <button><img src={Bpng} alt="B" /></button>
                                <button><img src={Italic} alt="italic" /></button>
                              </div>
                              <div className="CarouselText">
                                <textarea
                                  className="mt-3"
                                  name={`CarouselText-${card.id}`}
                                  id={`CarouselText-${card.id}`}
                                  placeholder="Enter Carousel Text"
                                  rows="3"
                                  value={carousalTextMessages[card.id] || ''}
                                  onChange={(e) => handleCarousalTextChange(e, card.id)}
                                  maxLength={160}
                                ></textarea>
                                <span>Max Characters: {carousalTextMessages[card.id]?.length || 0}/160</span>
                              </div>
                              <div className="CrouselBtnContain">
                                <label htmlFor={`Buttons-${card.id}`}>Buttons:</label>
                                <div className="row gy-2">
                                  <div className="col-6 pe-1">
                                    <div className="CarouselText">
                                      <input
                                        type="text"
                                        placeholder="Enter Button Text"
                                        value={buttonTextForCarousals[card.id] || ''}
                                        onChange={(e) => handleButtonTextChange(e, card.id)}
                                        maxLength={25}
                                      />
                                      <span>Max Characters: {buttonTextForCarousals[card.id]?.length || 0}/25</span>
                                    </div>
                                  </div>
                                  <div className="col-6 ps-1">
                                    <div className="CarouselText">
                                      <input
                                        type="text"
                                        placeholder="Enter Domain"
                                        value={domainForCarousals[card.id] || ''}
                                        onChange={(e) => handleDomainChange(e, card.id)}
                                        maxLength={2000}
                                      />
                                      <span>Max Characters: {domainForCarousals[card.id]?.length || 0}/2000</span>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="CarouselText">
                                      <input
                                        type="text"
                                        placeholder="Enter Quick Reply Button Text"
                                        value={quickReplyForCarousals[card.id] || ''}
                                        onChange={(e) => handleQuickReplyChange(e, card.id)}
                                        maxLength={25}
                                      />
                                      <span>Max Characters: {quickReplyForCarousals[card.id]?.length || 0}/25</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                          
                        </div>
                      )}
                      </div>
                    </div>
                </div>

                <div className="col-lg-12">
                  <div className="WB_template_header margin_top0 pb-0">
                    <h5 className="commonHeading FooterTitle">Footer !</h5>
                    <p className="FooterHintContent">
                      Add a short line of text to the bottom of your message
                      template{" "}
                    </p>
                  </div>
                  <div className="form_group">
                    {/* <label htmlFor="Text">
                        Enter Text: <span className="required_icon">*</span>
                      </label> */}
                   <input
        type="text"
        id="Text"
        placeholder="Enter Text"
        value={footerVal}
        onChange={handleFooterChange}
        maxLength={60}
      />
                    <span className="CharLimit">Max Characters : {footerMax}/60</span>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="WB_template_header">
                    <h5 className="commonHeading">Buttons Optional</h5>
                    <p>
                      {" "}
                      Create buttons that let customers respond to your message
                      or take action
                    </p>
                  </div>
                  <div className="ButtonGroupings">
                    <div className="row">
                      <div className="col-6">
                        <h4>Valid groupings</h4>
                        <ol>
                          <li>Quick Reply, Quick Reply</li>
                          <li>Quick Reply, Quick Reply, URL, Phone</li>
                          <li>URL, Phone, Quick Reply, Quick Reply</li>
                        </ol>
                      </div>
                      <div className="col-6">
                        <h4>Invalid groupings</h4>
                        <ol>
                          <li>Quick Reply, URL, Quick Reply</li>
                          <li>URL, Quick Reply, URL</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="selectOptionDiv">
                  <div className="row">
                    <div>
                      <div>
                        
                      <div
                          className="btn-group AddButtonGroup"
                          role="group"
                          aria-label="Button group with nested dropdown"
                        >
                          <button type="button" className="btn btn-primary">
                            Add Button
                          </button>

                          <div className="btn-group bordderL" role="group">
                            <button
                              id="btnGroupDrop1"
                              type="button"
                              className="btn btn-primary dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            ></button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="btnGroupDrop1"
                            >
                              <li>
                                <a className="dropdown-item" href="#"   onClick={(event) => {
                                                                    event.preventDefault(); // Prevent page refresh
                                                                     handleOptionSelect("Visit Website");
                                                                        }}>
                                Visit Website
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#"  onClick={(event) => {
                                                                    event.preventDefault(); // Prevent page refresh
                                                                     handleOptionSelect("Call Phone Number");
                                                                        }}>
                                Call Phone Number
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#"  onClick={(event) => {
                                                                    event.preventDefault(); // Prevent page refresh
                                                                     handleOptionSelect("Quick Reply");
                                                                        }}>
                                Quick Reply
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* <SplitButton
                          onOptionSelect={handleOptionSelect}
                          className="save"
                          
                        /> */}
                      </div>

                      {showCallToAction && (
                        <div className="form_group callToAction mt-3">
                          <h6>Visit Link</h6>

                         
                        
                          {additionalUrls.map((url) => (
                            <>
                              <div key={url.id} className="form-inline mt-2">
                                <div style={{ position: "relative" }}>
                                  <input
                                    type="text"
                                    id="buttonText"
                                    className="custom-input"
                                    placeholder="Enter Button Text"
                                    maxLength={25}
                                    value={url.buttonText}
                                    onChange={(e) =>
                                      
                                      setAdditionalUrls(
                                        additionalUrls.map((u) =>
                                          u.id === url.id
                                            ? {
                                                ...u,
                                                buttonText: e.target.value,
                                              }
                                            : u
                                        )
                                      )
                                    }
                                  />

                                  <div
                                    className="List_card"
                                    style={{
                                      position: "absolute",
                                      bottom: "-16px",
                                      width: "100%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "8px",
                                        marginTop: "0px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "8px",
                                          marginTop: "0px",
                                        }}
                                      >
                                        {url.buttonText.length}
                                      </span>{" "}
                                      Characters
                                    </span>

                                    <span
                                      style={{
                                        fontSize: "8px",
                                        marginTop: "0px",
                                      }}
                                    >
                                      Max Characters :{" "}
                                      <span
                                        style={{
                                          fontSize: "8px",
                                          marginTop: "0px",
                                        }}
                                      >
                                        25
                                      </span>{" "}
                                    </span>
                                  </div>
                                </div>

                                <select name="urlType" id="urlType" className="w-25 ms-2">
                                  <option value="Static">Static</option>
                                  <option value="Dynamic">Dynamic</option>
                                </select>

                                <input
                                  type="text"
                                  placeholder="Enter Domain https://domain.com"
                                  id="visitWebsiteDomain"
                                  value={url.domain}
                                  onChange={(e) =>
                                    setAdditionalUrls(
                                      additionalUrls.map((u) =>
                                        u.id === url.id
                                          ? { ...u, domain: e.target.value }
                                          : u
                                      )
                                    )
                                  }
                                />
                                <button
                                  className="actionBtn"
                                  onClick={() => handleRemoveUrl(url.id)}
                                >
                                  <DeleteIcon />
                                </button>
                              </div>
                            </>
                          ))}
                        </div>
                      )}

                      {showCallPhone && (
                        <div className="form_group callPhone mt-4">
                          <div className="form-inline align-items-end">
                            <div>
                              <h6>Caption</h6>
                              <div style={{ position: "relative" }}>
                                <input
                                  type="text"
                                  placeholder="Enter Caption"
                                  className="custom-input phoneInputText"
                                  maxLength={25}
                                  value={phoneNumberText}
                                  onChange={(event) =>
                                    setPhoneNumberText(event.target.value)
                                  }
                                />

                                <div
                                  className="List_card"
                                  style={{
                                    position: "absolute",
                                    bottom: "-16px",
                                    width: "100%",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "8px",
                                      marginTop: "0px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "8px",
                                        marginTop: "0px",
                                      }}
                                    >
                                      {phoneNumberText.length}
                                    </span>{" "}
                                    Characters
                                  </span>

                                  <span
                                    style={{
                                      fontSize: "8px",
                                      marginTop: "0px",
                                    }}
                                  >
                                    Max Characters :{" "}
                                    <span
                                      style={{
                                        fontSize: "8px",
                                        marginTop: "0px",
                                      }}
                                    >
                                      25
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div>
                              <h6 className="ms-1">Call Phone Number</h6>
                              <PhoneInput
                                defaultCountry="in"
                                id="visitWebsiteDomain"
                                value={phone}
                                onChange={(phone) => setPhone(phone)}
                                className="AddPhoneNumberContain"
                              />
                            </div>
                            <button
                              className="actionBtn"
                              onClick={() => {
                                setShowCallPhone(false);
                                setPhoneNumberText("");
                                setPhone("");
                                setTotalButtons(totalButtons - 1);
                              }}
                            >
                              <DeleteIcon className="redTrash" />
                            </button>
                          </div>
                        </div>
                      )}

                      {showQuickReply && (
                        <div className="form_group quickReply mt-5">
                          <h6>Quick Reply</h6>
                          {isNewQuickReply.map((reply) => (
                            <>
                              <div key={reply.id} className="form-inline mt-2">
                                <div style={{ position: "relative" }}>
                                  <input
                                    className="inputQuickReply"
                                    type="text"
                                    placeholder="Enter Button Text"
                                    maxLength={25}
                                    value={reply.buttonText}
                                    onChange={(e) =>
                                      setIsNewQuickReply(
                                        isNewQuickReply.map((r) =>
                                          r.id === reply.id
                                            ? {
                                                ...r,
                                                buttonText: e.target.value,
                                              }
                                            : r
                                        )
                                      )
                                    }
                                  />

                                  <div
                                    className="List_card"
                                    style={{
                                      position: "absolute",
                                      bottom: "-16px",
                                      width: "100%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "8px",
                                        marginTop: "0px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "8px",
                                          marginTop: "0px",
                                        }}
                                      >
                                        {reply.buttonText.length}
                                      </span>
                                      Characters
                                    </span>

                                    <span
                                      style={{
                                        fontSize: "8px",
                                        marginTop: "0px",
                                      }}
                                    >
                                      Max Characters :{" "}
                                      <span
                                        style={{
                                          fontSize: "8px",
                                          marginTop: "0px",
                                        }}
                                      >
                                        20
                                      </span>
                                    </span>
                                  </div>
                                </div>

                                <button
                                  className="actionBtn"
                                  onClick={() => {
                                    setIsNewQuickReply(
                                      isNewQuickReply.filter(
                                        (r) => r.id !== reply.id
                                      )
                                    );
                                    setTotalButtons(totalButtons - 1);
                                  }}
                                >
                                  <DeleteIcon />
                                </button>
                              </div>
                            </>
                          ))}
                        </div>
                      )}
                      
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-5">
                  <div className="form_group mt-0">
                    <button
                      type="button"
                      className="save "
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="cancel"
                      onClick={() => cancelNavigate("/wbtemplate")}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                {/* Spinner */}
                {loading && (
                  <div className="spinnerOverlay">
                    <ClipLoader loading={loading} size={50} />
                  </div>
                )}
                <ShowSnackBar
                  open={snackBar.open}
                  severity={snackBar.severity}
                  message={snackBar.message}
                  onClose={() =>
                    setSnackBar((prevState) => ({ ...prevState, open: false }))
                  }
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="Preview_Contain">
                <div className="Preview_head">
                  <div className="Preview_user">
                    <img src={User} alt="user icon" />
                  </div>
                  <h4 className="Preview_title">
                    {userInfo ? userInfo : "User Name"}
                  </h4>
                </div>
                <div className="Preview_card">
                  <div className="mt-3 mainBox">
                    {templateType === "Media" && (
                      <>
                        {selectedMedia === "Document" && (
                          <div className="showMedia">
                            
                            {isDocument ? (
                              
                              <a
                                href={fileURL}
                                className="uploaded-document"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Document
                              </a>
                            ) : <img src={pdfIcon} alt="pdf" className="MediaIcon"/>}
                          </div>
                        )}

                        {selectedMedia === "Video" && (
                          <div className="showVideo">
                            
                            {isVideo ?(
                              <video
                                autoPlay
                                controls
                                className="uploaded-video viewMedia"
                              >
                                <source src={fileURL} type={fileType} />
                                Your browser does not support the video tag.
                              </video>
                            ):<img src={videoIcon} alt="video"  className="MediaIcon"/>}
                          </div>
                        )}

                        {selectedMedia === "Image" && (
                          <div className="showImages">
                            {isImage ?(
                              <img
                                src={fileURL}
                                className="uploaded-image viewMedia"
                                alt="Uploaded Preview"
                              />
                            ):<img src={imageIcon} alt="img"  className="MediaIcon"/>}
                          </div>
                        )}
                        {selectedMedia === "Location" && (
                          <div className="showLocation">
                            <img src={locationIcon} alt="Location"  className="MediaIcon"/>
                          </div>
                        )}
                         {selectedMedia === "Carousel" && (
                          <div className="showCarousel">
                            <img src={carouselIcon} alt="carouselIcon"  className="MediaIcon"/>
                          </div>
                        )}
                      </>
                    )}

                    <p className="templateHeader">
                      {headerVal}
                    </p>
                    
                   

                    <div className="mt-2 templateMessage">
                      <p
                        className="message"
                        dangerouslySetInnerHTML={{
                          __html: formattedMessage,
                        }}
                      />
                    </div>
                    <p className="mt-2 templateFooter">
                      {footerVal}
                    </p>
                  </div>
                  <div id="previewBtn" className="PreviewBtnContain">
      {ButtonsArray.length > 0 && (
        <>
        {ButtonsArray.length <= 3 ?ButtonsArray.slice(0, showAll ? ButtonsArray.length : 3):ButtonsArray.slice(0, showAll ? ButtonsArray.length : 2)}
           
          {ButtonsArray.length > 3 && (
            <>
            {!showAll && ( 
              <button 
                type="button" 
                className="btn commonBtn mt-2 w-100" 
                onClick={() => setShowAll(true)}
              >
                Show More
              </button>
            )}
            {showAll && (
              <button 
                type="button" 
                className="btn commonBtn mt-2 w-100" 
                onClick={() => setShowAll(false)}
              >
                Show Less
              </button>
            )}
            </>
          )}
        </>
      )}
    </div>



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WbAddTemplate;
