import React, { useEffect, useState } from "react";
import "../css/wb_delivery_report.css";
import { apiCall } from "../services/authServieces";
import SearchIcon from "@mui/icons-material/Search";

const WbDeliveryReport = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mobilesrch, setmobilesrch] = useState("");

  const [showpagi, setshowpagi] = useState(false);

  const itemsPerPage = 10; // page limit

  const fetchData = async (page) => {
    try {
      const res = await apiCall({
        endpoint: `report/get-deliveryreport?page=${page}&limit=${itemsPerPage}`, // Use itemsPerPage
        method: "GET",
      });

      const result = res; // Use res instead of response
      setData(result.items);
      setTotalPages(Math.ceil(result.total / itemsPerPage));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Create pagination array
  const createPagination = () => {
    const pagesToShow = 1; // Number of pages to show around the current page
    let pages = [];

    // Add the first page
    pages.push(1);

    // Add ellipsis if the current page is far from the first page
    if (currentPage > pagesToShow + 2) {
      pages.push(null); // Ellipsis
    }

    // Add pages around the current page
    for (
      let i = Math.max(2, currentPage - pagesToShow);
      i <= Math.min(totalPages - 1, currentPage + pagesToShow);
      i++
    ) {
      pages.push(i);
    }

    // Add ellipsis if the current page is far from the last page
    if (currentPage < totalPages - pagesToShow - 1) {
      pages.push(null); // Ellipsis
    }

    // Add the last page if it's greater than 1
    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages;
  };

  const Handlesrchno = async () => {
    let payload = {
      mobileno: mobilesrch,
    };

    try {
      const res = await apiCall({
        endpoint: "report/searchdeliveryreportno",
        method: "POST",
        payload: payload,
      });

      if (res.data) {
        setData([]);

        const tabdata = res.data.map((item, index) => ({
          sno: index,
          send_date: item.send_date,
          send_time: item.send_time,
          delivery_status: item.delivery_status,
          msg: item.msg,
          temp_category:item.temp_category,
          sender_mobile: item.sender_mobile,
          msg_type: item.msg_type,
        }));

        setData(tabdata);

        setshowpagi(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="Delivery_report_contian">
        <h4 className="Head_title">
          Today Delivery Report{" "}
          <div className="SearchContain">
            <input
              type="text"
              placeholder="Search"
              value={mobilesrch}
              onChange={(e) => setmobilesrch(e.target.value)}
            />
            <button  type="button"  onClick={Handlesrchno}>
              <SearchIcon />
            </button>
          </div>{" "}
        </h4>

        <div className="Delivery_report_Card">
          <div className="table_contain_deliveryReport">
            <table className="Table" id="Table">
              <thead>
                <tr>
                  {/* <th>S_No.</th> */}
                  <th>Date</th>
                  <th>Mobile</th>
                  <th>Message</th>
                  <th>Message Category</th>
                  <th>Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    {/* <td>{(currentPage - 1) * itemsPerPage + index + 1}</td> */}
                    <td>
                      {item.send_date} {item.send_time}
                    </td>
                    <td>{item.sender_mobile}</td>
                    <td>{item.msg}</td>
                    <td>{item.temp_category}</td>
                    <td className="RunninFont">{item.msg_type}</td>
                    <td className="RunninFont">{item.delivery_status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className={`pagination  ${showpagi ? "d-none" : ""}`}>
          {createPagination().map((page, index) =>
            page === null ? (
              <span key={index} className="ellipsis">
                ...
              </span>
            ) : (
              <button
              key={index}
              className={`page-button ${currentPage === page ? 'active' : ''}`}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default WbDeliveryReport;
