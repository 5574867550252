import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import "../../css/walletalert.css";
// import { getAppBarUtilityClass } from '@mui/material';
// import { AddShoppingCart } from '@mui/icons-material';
import ShowSnackBar from "../../components/snackBar";
import TablePagination from "@mui/material/TablePagination";
import { apiCall } from "../../services/authServieces";



const OEMWalletAlert = () => {
  const [toRange, setToRange] = useState("");
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: true,
    message: "",
  });
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseSnackBar = () => {
    setSnackBar((prevState) => ({ ...prevState, open: false }));
  };
  // handle by default data
  const fetchData = useCallback(async () => {
    try {
      let endpoint = `oem/getUserBalanceData?limit=${rowsPerPage}&page=${page}`;

      if (toRange) {
        endpoint = `${endpoint}&fromRange=0&toRange=${toRange}`;
      }

      const response = await apiCall({ method: "GET", endpoint: endpoint });
      if (response.success) {
        setRows(response.data);
        setTotal(response.total_count);
      }
    } catch (error) {
      console.log(error);
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // handle submit range
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const endpoint = `oem/getUserBalanceData?limit=${rowsPerPage}&page=${page}&fromRange=0&toRange=${toRange}`;
      const response = await apiCall({ endpoint: endpoint, method: "GET" });
      if (response.success) {
        setRows(response.data);
        setTotal(response.total_count);
      }
    } catch (error) {
      console.log("Error got i project: ", error);
    }
  };

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 100000,
      label: "100000",
    },
  ];
  
  function valuetext(value) {
    if(value){
      setToRange(value)
    }
  }

  return (
    <>
      <div className="Mis_report_contian">
        <h4 className="Head_title">Wallet Alert</h4>
        <div className="AmountRangeBar">
          <Box
            sx={{
              width: "100%",
              maxWidth: 600,
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              gap: "40px",
            }}
          >
            <Slider
              aria-label="Always visible"
              defaultValue={0}
              getAriaValueText={valuetext}
              step={100}
              marks={marks}
              min={0}
              max={100000}
              valueLabelDisplay="on"
            />
            <button onClick={handleSubmit} className="GoButton">Go</button>
          </Box>

          {/* <div className="from-group">
            <label htmlFor="toRange">Max Amount</label>
            <input
              type="number"
              name="toRange"
              id="toRange"
              onChange={handleToRange}
            />
          </div>
          <button onClick={handleSubmit}>Submit</button> */}
        </div>
        <div className="Mis_report_Card122">
          <div className="table_contain1 tMisReport">
            <table className="Table" id="Table">
              <thead>
                <tr>
                  <th>User Id</th>
                  <th>User Name</th>
                  <th>Mobile Number</th>
                  <th>Wallet Alert</th>
                </tr>
              </thead>
              <tbody>
                {rows.length === 0 ? (
                  <tr>
                    <td colSpan="6" className="tableDataCenterPosition">
                      No data available
                    </td>
                  </tr>
                ) : (
                  rows.map((data, index) => (
                    <>
                      <tr key={index}>
                        <td>{data.user_id}</td>
                        <td>{data.user_name}</td>
                        <td>{data.user_phone}</td>
                        <td>{Number(data.balance).toFixed(3)}</td>
                      </tr>
                    </>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <ShowSnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={snackBar.message}
        onClose={handleCloseSnackBar}
        className="setMarginTop"
      />
    </>
  );
};

export default OEMWalletAlert;
