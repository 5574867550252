import React, { useState, useEffect, useCallback } from "react";
import "../../css/manage_waba.css";
import { apiCall } from "../../services/authServieces";
import ShowSnackBar from "../../components/snackBar";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation, useNavigate } from "react-router-dom";




const PendingReport = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const location = useLocation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { selectedRow } = location.state || {};
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const navigate = useNavigate();

  const columns = [
    { id: "created_by", label: "User ID", minWidth: 100 },
    { id: "user_name", label: "Name", minWidth: 100 },
    { id: "count", label: "Pending Messages", minWidth: 100 }
    // { id: "company_name", label: "Company", minWidth: 100 },
    // { id: "delivered", label: "Delivered", minWidth: 100 },
    // { id: "failed", label: "Failed", minWidth: 100 },
    // { id: "count", label: "Total", minWidth: 100 },
  ];

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseSnackBar = (event, reason) => {
    setSnackBar((prevState) => ({ ...prevState, open: false }));
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const fetchData = useCallback(async () => {
    try {
      let endpoint = `dashboard/get-pendingdata?page=${page}&limit=${rowsPerPage}`;
      if (debouncedSearchTerm) {
        endpoint = `${endpoint}&search=${debouncedSearchTerm}`;
        console.log("endpoint: ", endpoint);
      }
      const response = await apiCall({ method: "GET", endpoint: endpoint });
      if (response.success) {
        setRows(response.data);
        setTotal(response.total);
      }
    } catch (error) {
      console.error(error);
    }
  }, [page, rowsPerPage, selectedRow?.user_id, debouncedSearchTerm]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };



  const getCurrentDateInIST = () => {
    const now = new Date();

    // Convert to Indian Standard Time (IST)
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'long', // 'short' for abbreviated month names
      day: 'numeric',
    };

    return new Intl.DateTimeFormat('en-IN', options).format(now);
  };
  return (
    <>
      <div className="Template_id_contian">
        
        <h4 className="Head_title AdminSearchContain DailyReport">
          <div className="TodayDeliveryRepot">
          {getCurrentDateInIST()}
          </div>
          Live Pending Report
          <div className="Session_report_SearchContain">
            <input
              placeholder="Search"
              type="text"
              className=""
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button type="button">
              <SearchIcon />
            </button>
          </div>
        </h4>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table" className="Table DailyReportTable">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.created_by}
                    // onClick={() => handleRowClick(row)}
                    style={{ cursor: "pointer" }} // Adds a pointer cursor to indicate clickable rows
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return <TableCell key={column.id}>{value}</TableCell>;
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>

      <ShowSnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={snackBar.message}
        onClose={handleCloseSnackBar}
      />
    </>
  );
};
// Style for the modal
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default PendingReport;
