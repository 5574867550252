import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminPanel = () => {
    const [nodes, setNodes] = useState([]);
    const [text, setText] = useState('');
    const [nodeType, setNodeType] = useState('question');
    const [parentId, setParentId] = useState(null);

    useEffect(() => {
        // Fetch root nodes or children of a selected node
        const fetchNodes = async () => {
            const response = await axios.get('http://localhost:8000/nodes/' + (parentId || 'null'));
            setNodes(response.data);
        };

        fetchNodes();
    }, [parentId]);

    const handleSubmit = async () => {
        const response = await axios.post('http://localhost:8000/add-node', {
            parentId,
            text,
            nodeType,
            isTerminal: nodeType === 'answer',
        });

        setText('');
        setParentId(response.data.id);
    };

    return (
        <div>
            <h1>Chatbot Panel</h1>
            <div>
                <input
                    type="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Enter question or answer"
                />
                <select
                    value={nodeType}
                    onChange={(e) => setNodeType(e.target.value)}
                >
                    <option value="question">Question</option>
                    <option value="answer">Answer</option>
                </select>
                <button onClick={handleSubmit}>Add Node</button>
            </div>
            <div>
                <h2>Nodes</h2>
                <ul>
                    {nodes.map((node) => (
                        <li key={node.id} onClick={() => setParentId(node.id)}>
                            {node.text} ({node.node_type})
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default AdminPanel;