import React, { useState, useEffect } from 'react';
import '../css/CreateFirstPage.css';
import Plus from "../Assets/images/plus.png";
import { NavLink, useLocation } from 'react-router-dom';
import axios from 'axios';

const CreateFirstPage = () => {
  const [showModal, setShowModal] = useState(false); // Modal state
  const [message, setMessage] = useState(''); // State to store the message input
  const [messages, setMessages] = useState([]); // State to store list of messages for the bot

  const location = useLocation();
  const { botId, botName } = location.state || {}; // Destructure state passed from previous page

  // Fetch messages when the component mounts
  useEffect(() => {
    if (botId) fetchMessages();
  }, [botId]);

  // Function to fetch messages from the backend
  const fetchMessages = async () => {
    try {
      const response = await axios.get(`http://localhost:8000/api/getMessages?botId=${botId}`);
      const messages = response.data;

        console.log(response,'response')
      console.log(message,'message')
  
      // Check if the response is an empty array
      if (messages.length === 0) {
        alert('No messages found for this bot.'); // Inform the user
      } else {
        setMessages(messages); // Update state with fetched messages
      }
    } catch (error) {
    //   console.error('Error fetching messages:', error);
    //   alert('Error fetching messages. Please try again.'); // Error feedback
    }
  };
  

  // Function to handle button click to show modal
  const handleButtonClick = () => {
    setShowModal(true);
  };

  // Function to handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Function to handle message input change
  const handleMessageChange = (event) => {
    setMessage(event.target.value); // Update message state on input change
  };

  // Function to handle save button click
  const handleSaveClick = async () => {
    if (message.trim() !== '') { // Check if the message is not empty
      try {
        // Send a POST request to save the message with botId
        await axios.post('http://localhost:8000/api/saveWelcomeMessage', {
          botId: botId,
          botName: botName,
          message: message
        });

        alert('Message saved successfully!'); // Show success message
        setMessage(''); // Clear the input field
        setShowModal(false); // Hide the modal
        fetchMessages(); // Refresh the list of messages after saving a new one
      } catch (error) {
        console.error('Error saving message:', error);
        alert('Error saving message. Please try again.'); // Show error message
      }
    } else {
      alert('Please enter a message before saving.'); // Alert if no message is entered
    }
  };


  const toggleStatus = async (messageId, currentStatus) => {
    const newStatus = currentStatus === '0' ? 1 : 0;
    const action = newStatus === 1 ? 'activate' : 'deactivate';

    // Show confirmation dialog
    const confirmed = window.confirm(`Are you sure you want to ${action} this message?`);

    if (confirmed) {
      try {
        await axios.post('http://localhost:8000/api/updateMessageStatus', {
          messageId: messageId,
          status: newStatus
        });

        // alert('Status updated successfully!');
        fetchMessages(); // Refresh messages to reflect status changes
      } catch (error) {
        console.error('Error updating status:', error);
        alert('Error updating status. Please try again.');
      }
    }
  };

  return (
    <div>
      <button id='create-bot-button' className='create_bot_btn' onClick={handleButtonClick}>
        <img src={Plus} alt="img" /> Create Message
      </button>

      {/* Modal for creating a new message */}
      {showModal && (
        <div className="modal-overlay botf_modal_container">
          <button type="button" className="btn-close bot_modal_close_btn_f" onClick={handleCloseModal}></button>
          <div className="modal1_f">
            <h5>Enter your message</h5>
            <textarea
              type="text"
              className='modal_input_bot_f'
              placeholder="Enter Your Welcome message"
              value={message} // Bind the textarea value to message state
              onChange={handleMessageChange} // Handle change event
            />
            <div className="modal-buttons">
              <button className='ms-2 Modal_save_btn' onClick={handleSaveClick}>Save</button>
              <button className='ms-2 Modal_cancel_btn' onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* Table to display bot details and messages */}
      <div className="mt-4">
      <table className='table table-bordered Table_bot'>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Bot Name</th>
            <th>Message</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {messages.length > 0 ? (
            messages.map((msg, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{msg.bot_name}</td>
                <td>{msg.wel_message}</td>
                <td>{msg.status === '0' ? 'Inactive' : 'Active'}</td>
                <td>
                  <button
                    className={`btn btn-${msg.status === '0' ? 'success' : 'warning'} btn-sm me-2`}
                    onClick={() => toggleStatus(msg.id, msg.status)}
                  >
                    {msg.status === '0' ? 'Activate' : 'Deactivate'}
                  </button>
                  <NavLink to='/mainquestion'>
                    <button className="btn btn-primary btn-sm me-2">Add question</button>
                  </NavLink>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">No messages found</td>
            </tr>
          )}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default CreateFirstPage;
