import { createContext, useContext, useState } from "react";

export const UIContext = createContext();

export const useUIContext = () => useContext(UIContext);

export const UIProvider = ({ children }) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
  const [isUserDropDown, setIsUserDropDown] = useState(false);
  const [botId, setBotID] = useState();
  const [botName, setBotName] = useState();
  const [userType, setUserType] = useState(null);
  const [dateRanges, setDateRanges] = useState({
    one: [null, null],
    two: [null, null],
    mis: [null, null],
    misDetails: [null, null],
    modifiedReport: [null, null],
  });

  const resetContext = () => {
    setIsSidebarCollapsed(true);
    setIsUserDropDown(false);
    setBotID(undefined);
    setBotName(undefined);
    setUserType(null);
    setDateRanges({
      one: [null, null],
      two: [null, null],
      mis: [null, null],
      misDetails: [null, null],
      modifiedReport: [null, null],
    });
  };


  const value = {
    isSidebarCollapsed,
    setIsSidebarCollapsed,
    isUserDropDown,
    setIsUserDropDown,
    botId,
    setBotID,
    botName,
    setBotName,
    userType,
    setUserType,
    dateRanges,
    setDateRanges,
    resetContext
  };

  return (
    <UIContext.Provider value={value}>
      {children}
    </UIContext.Provider>
  );
};
